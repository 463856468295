import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table'; // Import Table component
import './Addservice.css';
import {jwtDecode} from 'jwt-decode'; // Removed curly braces for jwtDecode
import axios from 'axios';

const AddZipcode = () => {
  const [serviceData, setServiceData] = useState({
    City: '',
    State: '',
    zipcode: ''
  });
  const [userid, setUserid] = useState('');
  const [zipcodes, setZipcodes] = useState([]); // State to hold zipcodes
  const apiUrl = process.env.REACT_APP_API_URL; // Ensure the URL is correct

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    const fetchZipcodes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/Admin/address/get`);
        setZipcodes(response.data); // Assume the response data is an array of zipcodes
      } catch (error) {
        console.error('Error fetching zipcodes:', error);
      }
    };

    getUser();
    fetchUserInfo();
    fetchZipcodes(); // Fetch zipcodes when the component mounts
  }, [userid, apiUrl]); // Add dependencies to useEffect

  const handleChange = (e) => {
    const { id, value } = e.target;
    
    setServiceData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(serviceData); // Check the state before sending the request
    try {
      const response = await axios.post(`${apiUrl}/Admin/address/${userid}`, serviceData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setServiceData({
        City: '',
        State: '',
        zipcode: ''
      });
      console.log(response);
      alert('Zipcode added successfully');
      setZipcodes([...zipcodes, serviceData]); // Add the new zipcode to the table
    } catch (error) {
      alert("Zipcode already exists");
      console.error('Error adding zipcode:', error);
    }
  };
console.log(zipcodes);
  return (
    <div className="subadmindiv1">
      <div className="admindiv2">
        <h1 style={{ textAlign: 'center' }}>Add Zipcode</h1>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="City">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" placeholder="Enter City" onChange={handleChange} value={serviceData.City} />
            </Form.Group>
            <Form.Group as={Col} controlId="State">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" placeholder="Enter State" onChange={handleChange} value={serviceData.State} />
            </Form.Group>
            <Form.Group as={Col} controlId="zipcode">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control type="text" placeholder="Enter Zipcode" onChange={handleChange} value={serviceData.zipcode} />
            </Form.Group>
          </Row>
          <Button style={{ width: "200px" }} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <div style={{ marginTop: '20px' }}>
          <h2>Existing Zipcodes</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
              </tr>
            </thead>
            <tbody>
              {zipcodes.map((zipcode, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{zipcode.City}</td>
                  <td>{zipcode.State}</td>
                  <td>{zipcode.zipcode}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AddZipcode;
