import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const Spzipcode = () => {
    const [userRole, setUserRole] = useState('');
    const [formData, setFormData] = useState({
        zipCodes: []
    });
    const [Zipcodes, setZipcodes] = useState([]);
    const [fetchedServices, setFetchedServices] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedId } = location.state || {};
    const apiurl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    const resObject = await response.json();
                    setUserRole(resObject.user);
                } else {
                    throw new Error("Authentication failed");
                }
            } catch (err) {
                console.error(err);
            }
        };

        getUser();

        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    setUserRole(decodedToken.id);
                }
            } catch (error) {
                console.error('Error fetching user information:', error);
            }
        };

        fetchUserInfo();
    }, []);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`${apiurl}/Admin/address/zip-codes`);
                console.log(response.data);
                setZipcodes(response.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        const fetchZipcodes = async () => {
            try {
                const response = await axios.get(`${apiurl}/Admin/address/service-provider/${userRole}/zipcode`);
                console.log(response.data);
                setFetchedServices(response.data.assignedZipCodes);

            } catch (error) {
                console.error('Error fetching zip codes:', error);
            }
        };

        if (userRole) {
            fetchZipcodes();
        }
    }, [userRole]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setFormData(prevFormData => ({
                ...prevFormData,
                zipCodes: [...prevFormData.zipCodes, value]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                zipCodes: prevFormData.zipCodes.filter(zip => zip !== value)
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(formData);
        if (formData.zipCodes.length === 0) {
            alert("Please select at least one zipcode");
            return;
        }

        try {
            let response;
            console.log(formData);
            // Add new address
            response = await axios.post(`${apiurl}/Admin/address/assign-zipcodes/${userRole}`, formData);
            if (response.status === 200) {
                alert("Zipcodes assigned successfully");

                // Fetch the updated zip codes to reflect the changes instantly
                const updatedResponse = await axios.get(`${apiurl}/Admin/address/service-provider/${userRole}/zipcode`);
                setFetchedServices(updatedResponse.data.assignedZipCodes);

                // Optionally, reset the form
                setFormData({ zipCodes: [] });

                navigate('/Zipcode');
            } else {
                alert(response.data.message || "Failed to assign zipcodes");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Failed to assign zipcodes. Please try again later.");
        }
    };

    console.log(fetchedServices);

    return (
        <div className='address'>
            <div className='detmain1'>
                <div className="detdiv1">
                    <h1>Add Address</h1>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Form.Label className="deth6">Select Zipcodes</Form.Label>
                            {Array.isArray(Zipcodes) && Zipcodes.map(zipcode => (
                                <Form.Check
                                    key={zipcode.id}
                                    type="checkbox"
                                    id={`zipcode-${zipcode.id}`}
                                    label={zipcode}
                                    name="zipcodes"
                                    value={zipcode}
                                    checked={formData.zipCodes && formData.zipCodes.includes(zipcode)}
                                    onChange={handleCheckboxChange}
                                    disabled={fetchedServices.includes(zipcode)}
                                />
                            ))}
                        </Row>
                        <Button className="btmsu" type="submit">Assign Zipcodes</Button>
                    </Form>
                </div>
                {/* <div>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Zipcode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {fetchedServices.map((service) => (
                                <tr key={service._id}>
                                    <td>{service}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div> */}
            </div>
        </div>
    )
}

export default Spzipcode;
