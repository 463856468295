import React, { useEffect, useState } from 'react';
import './spavalibilty.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DatePicker from 'react-datepicker';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Adjusted import
import { Button } from 'react-bootstrap';

const SpAvalablity = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [selectedDay, setSelectedDay] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [savedAvailabilities, setSavedAvailabilities] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userid, setUserid] = useState('');
  const [error, setError] = useState('');
  const [vacationDate, setVacationDate] = useState(null);
  const [vacationDates, setVacationDates] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const fetchAvailabilities = async () => {
    try {
      const response = await axios.get(`https://homesalon.onrender.com/avaliblity/slots/${userid}`);
      console.log(response.data);
      const fetchedAvailabilities = response.data.reduce((acc, slot) => {
        if (!acc[slot.day]) {
          acc[slot.day] = [];
        }
        acc[slot.day].push(slot);
        return acc;
      }, {});
      setSavedAvailabilities(fetchedAvailabilities);
    } catch (error) {
      console.error('Error fetching availabilities:', error);
    }
  };

  const fetchVacationDates = async () => {
    try {
      const response = await axios.get(`${apiUrl}/avaliblity/vacation/${userid}`);
      setVacationDates(response.data);
    } catch (error) {
      console.error('Error fetching vacation dates:', error);
    }
  };

  useEffect(() => {
    if (userid) {
      fetchAvailabilities();
      fetchVacationDates();
    }
  }, [userid]);

  const handleDaySelection = (day) => {
    setSelectedDay(day);
    setDialogOpen(true);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const hoursInt = parseInt(hours, 10);
    const period = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = hoursInt % 12 === 0 ? 12 : hoursInt % 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleSaveAvailability = async () => {
    try {
      const formattedStartTime = formatTime(startTime);
      const formattedEndTime = formatTime(endTime);
      await axios.post('https://homesalon.onrender.com/avaliblity/create-slots', {
        userId: userid,
        day: selectedDay,
        startTime: formattedStartTime,
        endTime: formattedEndTime
      });

      setSelectedDay('');
      setDialogOpen(false);
      fetchAvailabilities();
    } catch (error) {
      console.error('Error saving availability:', error);
    }
  };

  const handleDeleteTimeSlot = async (day, index) => {
    try {
      const slotToDelete = savedAvailabilities[day][index];
      console.log(userid,slotToDelete.endTime,day);
      await axios.patch(`${apiUrl}/avaliblity/delete-slot`, {
       
          userId: userid,
          day: day,
          startTime: slotToDelete.startTime,
          endTime: slotToDelete.endTime,
         
       
      });

      fetchAvailabilities();
    } catch (error) {
      console.error('Error deleting time slot:', error);
    }
  };

  const handleAddVacation = async () => {
    if (vacationDate) {
      const formattedVacationDate = vacationDate.toLocaleDateString('en-US');
      try {
        await axios.post(`https://homesalon.onrender.com/avaliblity/vacation/${userid}`, {
          date: formattedVacationDate
        });
        setVacationDate(null);
        setError('');
        fetchVacationDates();
      } catch (error) {
        alert("You have an Order");
        console.error('Error adding vacation date:', error);
      }
    }
  };

  return (
    <div className="spadivselectservice">
      <div className="spaApp1">
        <div>
          <h2>Add Availability</h2>
          <div className="spacontainer">
            <div className="spasection">
              <p>Select a day to set your availability:</p>
              <div className="spaoptions">
                {daysOfWeek.map((day) => (
                  <div className='spavdiv1' key={day}>
                    <div className={`spaoption ${selectedDay === day ? 'selected' : ''}`} onClick={() => handleDaySelection(day)}>
                      {day}
                    </div>
                    <div>
                      {savedAvailabilities[day] && savedAvailabilities[day].length > 0 && (
                        <div className="saved-times">
                          {savedAvailabilities[day].map((time, index) => (
                            <div key={index} className="sptime-slot">
                              {time.startTime} - {time.endTime}
                              <Button variant="outline-danger" size="sm" onClick={() => handleDeleteTimeSlot(day, index)}>
                                <Delete fontSize="small" />
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Enter your custom time slots for {selectedDay}</DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div>
                  <label>Start Time</label>
                  <input
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </div>
                <div>
                  <label>End Time</label>
                  <input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSaveAvailability} color="primary">
                Save Availability
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <h2>Add Vacation</h2>
          <div className="vacation-section">
            <DatePicker
              selected={vacationDate}
              onChange={(date) => setVacationDate(date)}
              dateFormat="MM-dd-yyyy"
            />
            <Button onClick={handleAddVacation}>Add Vacation</Button>
            {error && <p className="error">{error}</p>}
            <div className="vacation-dates">
              {vacationDates.map((date, index) => (
                <div key={index} className="vacation-date">
                  {date.date}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpAvalablity;
