import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import './HorizontalCalendar.css';
import { Button } from "react-bootstrap";

const HorizontalCalendar = ({ onDateSelect, selectedDate }) => {
  const [dates, setDates] = useState([]);
  const scrollMenuRef = useRef(null);

  const getNextDates = () => {
    const today = dayjs();
    const dates = [];

    // Dates for the current month starting from today
    for (let i = today.date(); i <= today.daysInMonth(); i++) {
      dates.push(today.startOf('month').add(i - 1, 'day'));
    }

    // Dates for the next month
    const nextMonth = today.add(1, 'month');
    for (let i = 1; i <= nextMonth.daysInMonth(); i++) {
      dates.push(nextMonth.startOf('month').add(i - 1, 'day'));
    }

    return dates;
  };

  useEffect(() => {
    const initialDates = getNextDates();
    setDates(initialDates);
  }, []);

  useEffect(() => {
    if (scrollMenuRef.current && dates.length) {
      const selectedIndex = dates.findIndex(date => date.isSame(selectedDate, 'day'));
      if (selectedIndex !== -1) {
        const menuItem = scrollMenuRef.current.children[selectedIndex];
        if (menuItem) {
          const scrollLeft = menuItem.offsetLeft - scrollMenuRef.current.offsetWidth / 2 + menuItem.offsetWidth / 2;
          scrollMenuRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        }
      }
    }
  }, [selectedDate, dates]);

  const MenuItem = ({ day }) => {
    const isSelected = day.isSame(selectedDate, "day");

    const handleClick = () => {
      onDateSelect(day);
    };

    return (
      <div
        className={`menu-item dayItem ${isSelected ? "active" : ""}`}
        onClick={handleClick}
      >
        <h5 className="day">{day.format("ddd")}</h5>
        <span className="month">{day.format("MMM")}</span>
        <span className="number">{day.format("D")}</span>
      </div>
    );
  };

  const handleScrollLeft = () => {
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <div className="format-container">
      <Button className="scroll-arrow left-arrow" onClick={handleScrollLeft}>
        &lt;
      </Button>
      <div className="scroll-menu-container" ref={scrollMenuRef}>
        {dates.map((day) => (
          <MenuItem
            key={day.format("YYYY-MM-DD")}
            day={day}
          />
        ))}
      </div>
      <Button className="scroll-arrow right-arrow" onClick={handleScrollRight}>
        &gt;
      </Button>
    </div>
  );
};

export default HorizontalCalendar;
