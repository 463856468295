import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const Paymentassign = () => {
    const [userRole, setUserRole] = useState('');
    const [formData, setFormData] = useState({
        zipCodes: []
    });
    const [Zipcodes, setZipcodes] = useState([]);
    const [assignedZipcodes, setAssignedZipcodes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedId } = location.state || {};
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    const resObject = await response.json();
                    setUserRole(resObject.user.id);
                } else {
                    throw new Error("Authentication failed");
                }
            } catch (err) {
                console.error(err);
            }
        };

        getUser();

        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    setUserRole(decodedToken.id);
                }
            } catch (error) {
                console.error('Error fetching user information:', error);
            }
        };

        fetchUserInfo();
    }, []);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`${apiUrl}/payment/get`);
                setZipcodes(response.data);
            } catch (error) {
                console.error('Error fetching zip codes:', error);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        const fetchAddress = async () => {
          // console.log(selectedId);
            try {
                const response = await axios.get(`${apiUrl}/Admin/address/service-provider/${userRole}/payment`);
                console.log(response.data.assignedZipCodes);
                const addressData = response.data.assignedZipCodes;
                setFormData({
                    zipCodes: addressData.zipcodes || []
                });
                // Set assigned zipcodes
                setAssignedZipcodes(addressData || []);
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        };

        if (userRole) {
            fetchAddress();
        }
    }, [userRole]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setFormData(prevFormData => ({
                ...prevFormData,
                zipCodes: [...prevFormData.zipCodes, value]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                zipCodes: prevFormData.zipCodes.filter(zip => zip !== value)
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // const uid = userRole.id;
        if (formData.zipCodes.length === 0) {
            alert("Please select at least one zipcode");
            return;
        }

        try {
            let response = await axios.post(`${apiUrl}/payment/assign-Payment/${userRole}`, formData);
            if (response.status === 200) {
                alert("Zipcodes assigned successfully");
                navigate('/Zipcode'); // Redirect as needed
            } else {
                alert(response.data.message || "Failed to assign zipcodes");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Failed to assign zipcodes. Please try again later.");
        }
    };
console.log(assignedZipcodes);
    return (
        <div className='address'>
            <div className='detmain1'>
                <div className="detdiv1">
                    <h1>Add Address</h1>
                </div>

                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Label className="deth6">Select Zipcodes</Form.Label>
                        {Array.isArray(Zipcodes) && Zipcodes.map(zipcode => (
                            <Form.Check
                                key={zipcode.id}
                                type="checkbox"
                                id={`zipcode-${zipcode.id}`}
                                label={zipcode.name}
                                name="zipcodes"
                                value={zipcode.name}
                                checked={formData.zipCodes.includes(zipcode.name)}
                                onChange={handleCheckboxChange}
                                disabled={assignedZipcodes.includes(zipcode.name)}
                            />
                        ))}
                    </Row>
                    <Button className="btmsu" type="submit">Assign Payment</Button>
                </Form>
            </div>
        </div>
    )
}

export default Paymentassign;
