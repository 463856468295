// src/Customer/Context/store.js
import { createStore, combineReducers } from 'redux';
import cartReducer from './Action/cartReducer';

const rootReducer = combineReducers({
    cart: cartReducer
});

const store = createStore(rootReducer);

export default store;
