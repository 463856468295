import React, { useState } from 'react';
import './Book.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

const BookForm = () => {
  const [formData, setFormData] = useState({
    displayName: '',
    email: '',
    Time: '',
  });
  const cartItems = useSelector(state => state.cart.cartItems);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedAddress, cid } = location.state;
  console.log(selectedAddress);
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const customerId = cid; // Assuming customerId is stored in localStorage
    const cname= `${selectedAddress.fname} ${selectedAddress.lname}`;
    const Address= `${selectedAddress.Al1} ${selectedAddress.Al2} ${selectedAddress.city} ${selectedAddress.state} ${selectedAddress.zipcode}`;
    const Comment = 'Some comment'; // Replace with actual comment if available
    const services = cartItems.map((item) => ({
      name: item.service.serviceName,
      cost: item.service.cost,
      duration: item.service.duration,
    }));
    const discount = 0; // Replace with actual discount if available
    const Bdate = formData.email;
    const Time = formData.Time;
    const paymentMethod = 'Some payment method'; // Replace with actual payment method if available
    const paymentId = 'Some payment ID'; // Replace with actual payment ID if available
console.log(services);
    try {
      const response = await axios.post('https://homesalon.onrender.com/order/Open-order', {
        customerId,
        cname,
        Address,
        Comment,
        services,
        discount,
        Bdate,
        Time,
        paymentMethod,
        paymentId,
      });

      if (response.status === 201) {
        navigate('/Vieworder');
      } else {
        console.error('Error creating order:', response.data);
      }
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  return (
    <div className='blogin'>
      <div className='blogindiv1'>
        <form onSubmit={handleSubmit}>
          <h1>Request To Service Provider</h1>
          <div className="bcontainerlo">
            {cartItems.map((item, index) => (
              <div key={index}>
                <label htmlFor="displayName">
                  <b>Service Name:</b>
                </label>
                <p>{item.service.serviceName}</p>
              </div>
            ))}
            <div>
              <label htmlFor="email"><b>Select Date</b></label>
              <input
                type="date"
                placeholder="Enter Date"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="Time"><b>Select Time</b></label>
              <input
                type="time"
                placeholder="Enter Time"
                name="Time"
                required
                value={formData.Time}
                onChange={handleChange}
              />
            </div>
            <Button className="bbtmsu" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookForm;
