import React from 'react'
import './Loading.css'
const Loading = () => {
  return (
    <div style={{marginTop:"250px"}}>
      <div class="cssload-main">
	<div class="cssload-heart">
		<span class="cssload-heartL"></span>
		<span class="cssload-heartR"></span>
		<span class="cssload-square"></span>
	</div>
	<div class="cssload-shadow"></div>
</div>
    </div>
  )
}

export default Loading
