import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import './Addservice.css';
// import {jwtDecode} from 'jwt-decode'; // Corrected the import statement
import axios from 'axios'; // Import axios for making HTTP requests
import { useNavigate } from 'react-router-dom';

// const Addservices = () => {
//   const navigation = useNavigate();
//   // const [userid, setUserid] = useState('');
//   const [serviceData, setServiceData] = useState({
//     serviceImage: null,
//     serviceName: '',
//     serviceType: '',
//     // cost: '',
//     // time: '',
//     description: '',
//     safety: '',
//     thingsToKnow: '',
//     Share: '',
//   });
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const [serviceTypes, setServiceTypes] = useState([]); // State for service types
//   const [services, setServices] = useState([]); // State for storing fetched services
  
//   useEffect(() => {
//     const getUser = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/auth/login/success`, {
//           method: "GET",
//           credentials: "include",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//         });
//         if (response.status === 200) {
//           // const resObject = await response.json();
//           // setUserid(resObject.user.id);
//         } else {
//           throw new Error("Authentication failed");
//         }
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     getUser();

//     const fetchUserInfo = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         if (token) {
//           // const decodedToken = jwtDecode(token);
//           // setUserid(decodedToken.id);
//         }
//       } catch (error) {
//         console.error('Error fetching user information:', error);
//       }
//     };

//     fetchUserInfo();

//     const fetchServiceTypes = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/service/servicetypes`);
//         setServiceTypes(response.data);
//       } catch (error) {
//         console.error('Error fetching service types:', error);
//       }
//     };

//     fetchServiceTypes();

//     const fetchServices = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/service/services`);
//         setServices(response.data);
//       } catch (error) {
//         console.error('Error fetching services:', error);
//       }
//     };

//     fetchServices();
//   }, []);

//   const handleChange = (e) => {
//     const { id, value, files } = e.target;
//     setServiceData((prevData) => ({
//       ...prevData,
//       [id]: files ? files[0] : value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     console.log("inside");
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('serviceImage', serviceData.serviceImage);
//     formData.append('serviceName', serviceData.serviceName);
//     formData.append('serviceType', serviceData.serviceType);
//     // formData.append('cost', serviceData.cost);
//     // formData.append('time', serviceData.time);
//     formData.append('description', serviceData.description);
//     formData.append('safety', serviceData.safety);
//     formData.append('thingsToKnow', serviceData.thingsToKnow);
//     formData.append('Share', serviceData.Share);

//     try {
//       const response = await axios.post(`${apiUrl}/service/mainservice`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log(response);
//       alert('Service added successfully');
//       setServiceData({
//         serviceImage: null,
//         serviceName: '',
//         serviceType: '',
//         description: '',
//         safety: '',
//         thingsToKnow: '',
//         Share: '',
//       });
//     } catch (error) {
//       alert("Service Name Already Inserted")
//       console.error('Error adding service:', error);
//     }
//   };

//   return (
//     <div className="admindiv1">
//       <div className="admindiv2">
     
//       <Button onClick={()=>{navigation('/Addsubservice')}}>Add Service</Button>
//         <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Service List</h2>
//         <div className="tablecontainer">
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Service Image</th>
//               <th>Service Name</th>
//               <th>Service Type</th>
//               <th>Description</th>
//               <th>Safety</th>
//               <th>Things To Know</th>
//               <th>Share</th>
//             </tr>
//           </thead>
//           <tbody>
//             {services.map((service) => (
//               <tr key={service._id}>
//                 <td><img src={service.serviceImage} alt="Service" style={{ width: '100px' }} /></td>
//                 <td>{service.serviceName}</td>
//                 <td>{service.serviceType}</td>
//                 <td>{service.description}</td>
//                 <td>{service.safety}</td>
//                 <td>{service.thingsToKnow}</td>
//                 <td>{service.Share}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         </div>
       
//       </div>
//     </div>
//   );
// };

// export default Addservices;


const Addservices = () => {
  const navigation = useNavigate();
  // const [userid, setUserid] = useState('');
  const [serviceData, setServiceData] = useState({
    serviceImage: null,
    serviceName: '',
    serviceType: '',
    // cost: '',
    // time: '',
    description: '',
    safety: '',
    thingsToKnow: '',
    Share: '',
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const [serviceTypes, setServiceTypes] = useState([]); // State for service types
  const [services, setServices] = useState([]); // State for storing fetched services
  
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch(`${apiUrl}/auth/login/success`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // const resObject = await response.json();
          // setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // const decodedToken = jwtDecode(token);
          // setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();

    const fetchServiceTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service/servicetypes`);
        setServiceTypes(response.data);
      } catch (error) {
        console.error('Error fetching service types:', error);
      }
    };

    fetchServiceTypes();

    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service/services`);
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setServiceData((prevData) => ({
      ...prevData,
      [id]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("inside");
    e.preventDefault();
    const formData = new FormData();
    formData.append('serviceImage', serviceData.serviceImage);
    formData.append('serviceName', serviceData.serviceName);
    formData.append('serviceType', serviceData.serviceType);
    // formData.append('cost', serviceData.cost);
    // formData.append('time', serviceData.time);
    formData.append('description', serviceData.description);
    formData.append('safety', serviceData.safety);
    formData.append('thingsToKnow', serviceData.thingsToKnow);
    formData.append('Share', serviceData.Share);

    try {
      const response = await axios.post(`${apiUrl}/service/mainservice`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      alert('Service added successfully');
      setServiceData({
        serviceImage: null,
        serviceName: '',
        serviceType: '',
        description: '',
        safety: '',
        thingsToKnow: '',
        Share: '',
      });
    } catch (error) {
      alert("Service Name Already Inserted")
      console.error('Error adding service:', error);
    }
  };
  return (
    <div>
      <div className="admindiv1">
      <div className="admindiv2">
     
            <Button onClick={()=>{navigation('/Addsubservice')}}>Add Service</Button>
            <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Service List</h2>
     </div>
     <div>
     <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Service Image</th>
              <th>Service Name</th>
              <th>Service Type</th>
              <th>Description</th>
              <th>Safety</th>
              <th>Things To Know</th>
              <th>Share</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service._id}>
                <td><img src={service.serviceImage} alt="Service" style={{ width: '100px' }} /></td>
                <td>{service.serviceName}</td>
                <td>{service.serviceType}</td>
                <td>{service.description}</td>
                <td>{service.safety}</td>
                <td>{service.thingsToKnow}</td>
                <td>{service.Share}</td>
              </tr>
            ))}
          </tbody>
        </Table>
     </div>
      </div>
    </div>
  )
}

export default Addservices