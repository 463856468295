import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import {  useNavigate } from 'react-router-dom';

const Loginupdate = () => {
  const [userdetail, setuserdetail] = useState({});
  const navigate = useNavigate()
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setuserdetail(resObject.user);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setuserdetail(decodedToken);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleChange = (e) => {
    setuserdetail({ ...userdetail, [e.target.name]: e.target.value });
  };
console.log(userdetail);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://homesalon.onrender.com/register/api/users/${userdetail.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          displayName: userdetail.displayName,
          email: userdetail.email,
          phone:userdetail.phone
          // Add more fields here as needed
        }),
      });

      if (response.ok) {
        navigate('/personalinfo')
        // Optionally, you can navigate to another page or perform additional actions upon successful update
      } else {
        const errorData = await response.json();
        alert(`Failed to update user details: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      alert('An error occurred while updating user details. Please try again.');
    }
  };

  return (
    <div style={{ margin: "150px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formDisplayName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            name="displayName"
            value={userdetail.displayName || ''}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={userdetail.email || ''}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="phone"
            placeholder="Enter Phone Number"
            name="phone"
            value={userdetail.phone || ''}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Update Details
        </Button>
      </Form>
    </div>
  );
}

export default Loginupdate;
