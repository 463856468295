import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ButtonGroup, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import Loading from '../../Loading';

// Register chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Adminorder = () => {
  const [serviceProviders, setServiceProviders] = useState([]);
  const [timeRange, setTimeRange] = useState('weekly');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);  // Set default rows per page to 4
  const [statusFilter, setStatusFilter] = useState(''); // State for status filter

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://homesalon.onrender.com/order/vieworder');
        setServiceProviders(response.data);
        setSelectedOrders(response.data);  // Set all orders by default
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  if (!serviceProviders.length) {
    return <div style={{ margin: "350px" }}>
      <Loading />
    </div>;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  const getCurrentDate = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}_${day}_${year}`;
  };
  

  const processData = (data, range) => {
    const groupedData = data.reduce((acc, order) => {
      const date = new Date(order.Bdate);
      let key;

      switch (range) {
        case 'monthly':
          key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          break;
        case 'yearly':
          key = `${date.getFullYear()}`;
          break;
        case 'weekly':
        default:
          const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
          key = startOfWeek.toDateString();
          break;
      }

      if (!acc[key]) {
        acc[key] = { count: 0, orders: [] };
      }
      acc[key].count += 1;
      acc[key].orders.push(order);
      return acc;
    }, {});

    return groupedData;
  };

  const orderData = processData(serviceProviders, timeRange);

  const chartData = {
    labels: Object.keys(orderData),
    datasets: [
      {
        label: 'Number of Orders',
        data: Object.values(orderData).map(item => item.count),
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Customize bar color
        borderColor: 'rgba(54, 162, 235, 1)', // Customize border color
        borderWidth: 2, // Customize border width
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Orders per ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        barPercentage: 0.5, // Adjust this value to reduce the space between bars
        categoryPercentage: 0.5, // Adjust this value to reduce the space between bars
      },
      y: {
        beginAtZero: true,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const label = chartData.labels[index];
        setSelectedOrders(orderData[label].orders);
      }
    },
  };

  const generatePDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = ["Order Date","Customer Name","Service Provider Name","Services","Booking Date","Total Cost","Final Cost","Order Status","Payment Status","Payment Method" ];
    const tableRows = [];

    selectedOrders.forEach(order => {
      const orderData = [
        formatDate(order.orderDate),
        order.cname,
        order.Providername,
        order.services.map(service => service.name).join(', '),
        order.Bdate,
        order.Totalcost,
        order.finalTotal,
        order.status,
        order.paymentStatus,
       order.paymentMethod

      ];
      tableRows.push(orderData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("Order Report", 14, 15);
    const filename = `order_report_${getCurrentDate()}.pdf`;
    doc.save(filename);
  };

  const generateExcel = () => {
    const ws = XLSX.utils.json_to_sheet(selectedOrders.map(order => ({
      'Order Date': formatDate(order.orderDate),
      'Customer Name': order.cname,
      'Service Provider Name': order.Providername,
      'Address': order.Address,
      'Services': order.services.map(service => service.name).join(', '),
      'Booking Date': order.Bdate,
      'Booking Time': order.Time,
      'Total Cost': order.Totalcost,
      'Discount': order.discount,
      'Final Cost': order.finalTotal,
      'Admin Share': order.adminShare,
      'Service Provider Share': order.serviceProviderShare,
      'Order Status': order.status,
      'Payment Status': order.paymentStatus,
      'Payment Method': order.paymentMethod,
      'Payment Id': order.paymentId
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const filename = `orders_report_${getCurrentDate()}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const calculateTotalCost = () => {
    return selectedOrders.reduce((total, order) => total + parseFloat(order.Totalcost), 0);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const handleFilterChange = (status) => {
    setStatusFilter(status);
    if (status === '') {
      setSelectedOrders(serviceProviders);
    } else {
      setSelectedOrders(serviceProviders.filter(order => order.status === status));
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
       <h1 className='ah1'>Order Details</h1>
       <ButtonGroup>
          <Button variant={statusFilter === '' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('')}>All</Button>
          <Button variant={statusFilter === 'Completed' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('Completed')}>Complete</Button>
          <Button variant={statusFilter === 'Pending' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('Pending')}>Open</Button>
          <Button variant={statusFilter === 'Accept' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('Accept')}>Accept</Button>
          <Button variant={statusFilter === 'rejected' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('rejected')}>Reject</Button>
          <Button variant={statusFilter === 'Cancel' ? 'primary' : 'secondary'} onClick={() => handleFilterChange('Cancel')}>Cancel</Button>
        </ButtonGroup>
       <DropdownButton id="time-range-dropdown" title={`Time Range: ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`} style={{ marginTop: '20px' }}>
        <Dropdown.Item onClick={() => setTimeRange('weekly')}>Weekly</Dropdown.Item>
        <Dropdown.Item onClick={() => setTimeRange('monthly')}>Monthly</Dropdown.Item>
        <Dropdown.Item onClick={() => setTimeRange('yearly')}>Yearly</Dropdown.Item>
      </DropdownButton>
       <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table aria-label="simple table" id='my-table'>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Service Provider Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Services</TableCell>
            <TableCell>Booking Date</TableCell>
            <TableCell>Booking Time</TableCell>
            <TableCell>Total Cost</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Final Cost</TableCell>
            <TableCell>Admin Share</TableCell>
            <TableCell>Service provider Share</TableCell>
            <TableCell>Order Status</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Payment Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {selectedOrders
          .filter(order => statusFilter === '' || order.status === statusFilter) // Apply filter
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((serviceProvider, index) => (
                <TableRow key={serviceProvider._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Serial number */}
                  <TableCell>{formatDate(serviceProvider.orderDate)}</TableCell>
                  <TableCell>{serviceProvider.cname}</TableCell>
                  <TableCell>{serviceProvider.Providername}</TableCell>
                  <TableCell>{serviceProvider.Address}</TableCell>
                  <TableCell>
                    {serviceProvider.services.map((service, index) => (
                      <div key={index}>{service.name}</div>
                    ))}
                  </TableCell>
                  <TableCell>{serviceProvider.Bdate}</TableCell>
                  <TableCell>{serviceProvider.Time}</TableCell>
                  <TableCell>${serviceProvider.Totalcost}</TableCell>
                  <TableCell>{serviceProvider.discount}</TableCell>
                  <TableCell>${serviceProvider.finalTotal}</TableCell>
                  <TableCell>{serviceProvider.adminShare}</TableCell>
                  <TableCell>{serviceProvider.serviceProviderShare}</TableCell>
                  <TableCell>{serviceProvider.status}</TableCell>
                  <TableCell>{serviceProvider.paymentStatus}</TableCell>
                  <TableCell>{serviceProvider.paymentMethod}</TableCell>
                  <TableCell>{serviceProvider.paymentId}</TableCell>
                </TableRow>
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan="8">Total Cost</TableCell>
            <TableCell colSpan="6">{formatCurrency(calculateTotalCost())}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <TablePagination
        rowsPerPageOptions={[4, 10, 25]}
        component="div"
        count={selectedOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
      <Button style={{ marginTop: '20px' }} onClick={generatePDF}>Generate PDF</Button>
      <Button style={{ marginTop: '20px', marginLeft: '10px' }} onClick={generateExcel}>Generate Excel</Button>
      <div style={{ marginTop: "50px" }}>
        <h2>Orders Overview</h2>
        
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}

export default Adminorder;
