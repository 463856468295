import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Addservice.css';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Addservicess = () => {
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState({
    serviceImage: null,
    serviceTypeName: '',
  });
  const [serviceTypes, setServiceTypes] = useState([]);
  const [services, setServices] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // const resObject = await response.json();
          // setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // const decodedToken = jwtDecode(token);
          // setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();

    const fetchServiceTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service/servicetypes`);
        setServiceTypes(response.data);
      } catch (error) {
        console.error('Error fetching service types:', error);
      }
    };

    fetchServiceTypes();

    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service/servicetypes`);
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [apiUrl]);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setServiceData((prevData) => ({
      ...prevData,
      [id]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('serviceImage', serviceData.serviceImage);
    formData.append('serviceTypeName', serviceData.serviceTypeName);

    try {
      const response = await axios.post(`${apiUrl}/service/service-type`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setServiceData({
        serviceImage: null,
        serviceTypeName: '',
      });
      console.log(response);
      alert('Service added successfully');
      navigate('/add-service');
    } catch (error) {
      alert("Service Name Already Inserted");
      console.error('Error adding service:', error);
    }
  };

  return (
    <div className="subadmindiv1">
      <div className="admindiv2">
        <h1 style={{ textAlign: 'center' }}>Add Service</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="serviceImage">
            <Form.Label>Service Image</Form.Label>
            <Form.Control type="file" accept="image/*" onChange={handleChange} required />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="serviceTypeName">
              <Form.Label>Service Type Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Service Name" onChange={handleChange} required />
            </Form.Group>
          </Row>
          <Button style={{ width: "200px" }} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Addservicess;
