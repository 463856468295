import React from 'react'
import { Button } from 'react-bootstrap'
import './profiledetail.css'

import { Link, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Loginedit = () => {
  const navigation =useNavigate()
  const goBack = () => {
    navigation(-1); // Navigate back in the browser history
  };
  return (
    <div>
         <div className='detmain'>
  
      <div className="detdiv1">
        <div className="adiv11" onClick={(()=>{goBack()})}>

<ArrowBackIcon/>
</div>
        <h1>
          Login
          
        </h1>
      </div>
      <form action="" method="get">


      <div className="detdiv2">
        <div className="detdiv3">

        <h4 className="deth6">Password</h4>
      
        </div>
        
        <div className="detdiv4">
            <Link>Cancel</Link>
        </div>
      </div>
      
      <div className="detdiv2">
        
        <div className="detdiv3">

        <h6 className="deth6">Current Password</h6>
       <input type='password' className='depass'></input>
        </div>
      
      </div>
    
      <div className="detdiv2">
        <div className="detdiv3">

        <h6 className="deth6">New Password</h6>
        <input type='password' className='depass'></input>
        </div>
       
      </div>
  
      <div className="detdiv2">
        <div className="detdiv3">

        <h6 className="deth6">Confirm Password</h6>
        <input type='password' className='depass'></input>
        </div>
       
      </div>
      <Button >Update Password</Button>
      </form>
    </div>
    </div>
  )
}

export default Loginedit
