import React from 'react'
import Address from '../Address'
// import { Margin } from '@mui/icons-material'

const Addressview = () => {
  return (
    <div className='addressdiv1'>
        <h1>Your Address</h1>
        <Address/>
    </div>
  )
}

export default Addressview