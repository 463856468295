import { ADD_TO_CART, REMOVE_FROM_CART, TOGGLE_CART_DRAWER, SET_CART_ITEMS, CLEAR_CART_ITEMS, INCREMENT_QUANTITY, DECREMENT_QUANTITY } from './actionType';

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (index) => ({
  type: REMOVE_FROM_CART,
  payload: index,
});

export const toggleCartDrawer = () => ({
  type: TOGGLE_CART_DRAWER,
});

export const setCartItems = (items) => ({
  type: SET_CART_ITEMS,
  payload: items,
});

export const clearCartItems = () => ({
  type: CLEAR_CART_ITEMS,
});

export const incrementQuantity = (index) => ({
  type: INCREMENT_QUANTITY,
  payload: index,
});

export const decrementQuantity = (index) => ({
  type: DECREMENT_QUANTITY,
  payload: index,
});
