import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table'; // Import Table component
import './Addservice.css';
import axios from 'axios';

const Adminpayment = () => {
  const [serviceData, setServiceData] = useState({
    name: '', // Change Payment to name
  });
  const [paymentMethods, setPaymentMethods] = useState([]); // State for storing payment methods
  const apiUrl = process.env.REACT_APP_API_URL; // Ensure the URL is correct

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // Handle successful login
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // Handle token logic
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();

    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get(`${apiUrl}/payment/get`); // Adjust endpoint if necessary
        console.log(response.data);
        setPaymentMethods(response.data);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods();
  }, [apiUrl]); // Add apiUrl as a dependency if it can change

  const handleChange = (e) => {
    const { id, value } = e.target;
    setServiceData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(serviceData); // Check the state before sending the request
    try {
      const response = await axios.post(`${apiUrl}/payment/add`, serviceData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setServiceData({
        name: '', // Reset the name field
      });
      console.log(response);
      alert('Payment method added successfully');
      // Refresh payment methods list after adding a new method
      // fetchPaymentMethods();
    } catch (error) {
      alert("Payment method already exists");
      console.error('Error adding payment method:', error);
    }
  };

  return (
    <div className="subadmindiv1">
      <div className="admindiv2">
        <h1 style={{ textAlign: 'center' }}>Add Payment Method</h1>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="name"> {/* Change controlId to name */}
              <Form.Label>Payment Method</Form.Label>
              <Form.Control type="text" placeholder="Enter Payment Method" onChange={handleChange} value={serviceData.name} /> {/* Change value to serviceData.name */}
            </Form.Group>
          </Row>
          <Button style={{ width: "200px" }} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Payment Methods List</h2>
        <div className="tablecontainer">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethods.map((method, index) => (
                <tr key={index}>
                  <td>{method.name}</td> {/* Adjust if method has a different property */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Adminpayment;
