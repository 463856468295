import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import './Rejection.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Rejection = () => {
  const [rejectComment, setRejectComment] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const { sid, orderId } = location.state || {};
const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://homesalon.onrender.com/order/orders/reject', {
        orderId,
        sid,
        rejectComment
      });

      if (response.status === 200) {
        navigate('/sp-order')
      } else {
        setMessage('Failed to reject the order');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while rejecting the order');
    }
  };

  return (
    <div style={{ marginTop: '150px' }}>
      <div className="rejdiv">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Why Reject the services?</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={rejectComment}
              onChange={(e) => setRejectComment(e.target.value)}
              required
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default Rejection;
