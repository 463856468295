import {
  MDBCard,
  MDBCardBody,

  MDBCardHeader,

  MDBCol,
  MDBContainer,

  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from 'react';
import './order.css'
import { Button } from "react-bootstrap";
// import { useLocation, useNavigate } from "react-router-dom";
import Cartcop from "../../Pages/Service/Cartcop";
import { jwtDecode } from 'jwt-decode';
// import { addToCart} from '../../Context/Action/actions'; // import your actions
import { useSelector } from "react-redux";
// import img1 from '../../Componenet/image/salon1.png';
import axios from "axios";
const Vieworders = ({ checklogin }) => {
  // const [expanded, setExpanded] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [cid, setcid] = useState('');
  const [displayname, setdisplayname] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to manage the drawer
  // const [cartItems, setCartItems] = useState([]);\
  // const [selectedTab, setSelectedTab] = useState('upcoming');
  const [Acceptorder, setAcceptorder] = useState([]);
  const [serviceProviders, setserviceProviders] = useState([]);


  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cartItems);
  // const location = useLocation();
  // const { selectedProviderDisplayName, selectedAvailability, selectedDate, selectedAddress } = location.state;
  // console.log(selectedDate);
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        // checklogin();
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          console.log(decodedToken);
          setUserRole(decodedToken.role);
          setdisplayname(decodedToken.displayName);
          setcid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);
  console.log(cid);
  // const handleLinkClick = () => {
  //   setExpanded(false); // Close the navbar when a link is clicked
  // };


  // const handleTabChange = (tab) => {
  //   setSelectedTab(tab);
  // };



  const toggleDrawer = () => {
    // handleLinkClick();
    setIsDrawerOpen(!isDrawerOpen);
  };
  // const handleAddToCart = (service) => {
  //   dispatch(addToCart(service));
  //   setIsDrawerOpen(true);
  // };
  useEffect(() => {
    console.log("useeffect", cid);
    const fetchsporder = async () => {
      try {
        const currentDate = new Date();
        const response = await axios.get(`https://homesalon.onrender.com/order/mostrecent`);
        console.log(response.data);
        setAcceptorder(response.data);
        if (response.data.Bdate > currentDate) {
          console.log(response.data);

        }
        // setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchsporder();
  }, [cid]);
  useEffect(() => {
    console.log("useeffect", cid);
    const fetchsp = async () => {
      try {
        const currentDate = new Date();
        const response = await axios.get(`https://homesalon.onrender.com/order/orders-by-customer/Completed/${cid}`);
        console.log(response);
        setserviceProviders(response.data);
        if (response.data.Bdate > currentDate) {
          console.log(response.data);

        }
        // setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchsp();
  }, [cid]);
  console.log(serviceProviders);
  // const formattedDate = `${selectedDate.$d.getDate()} ${selectedDate.$d.toLocaleString('default', { month: 'long' })} ${selectedDate.$d.getFullYear()}`;

  // const cart=cartItems.length
  // console.log(cart,"inside service");
  const now = new Date();
  // console.log(new Date(order.Bdate));
  const pastOrders = serviceProviders.filter(order => new Date(order.Bdate) < now);
  const upcomingOrders = serviceProviders.filter(order => new Date(order.Bdate) >= now);
  console.log(pastOrders);
  console.log(upcomingOrders);
  return (
    <div className="Div1">
      <section className="h-100 gradient-custom" style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center-1 h-100">
            <MDBCol lg="10" xl="8">
              <MDBCard style={{ borderRadius: "10px" }}>
                <MDBCardHeader className="px-4 py-5 card">
                  <MDBTypography tag="h5" className="text-muted mb-0">
                    Here your Order, <span style={{ color: "#a8729a" }}>{displayname}</span>!
                  </MDBTypography>
                </MDBCardHeader>
                <MDBCardBody>
                {Acceptorder.map((Acceptorder) => (
                
                <div className="ordiv1" key={Acceptorder.id}>
                <div className="ordiv2">
                  <div className="ordiv3">
                    <div>
                      <b style={{ color: "rgb(168, 114, 154)" }}>{Acceptorder.Time}</b>
                    </div>
                    <div>
                      <b style={{ fontSize: "2em", color: "rgb(168, 114, 154)" }}>
                        {new Date(Acceptorder.Bdate).toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric'
                        }).replace(',', '')}
                      </b>
                    </div>
                  </div>
                  <div className="ordiv4">
                    <b style={{ color: "rgb(168, 114, 154)" }}>Duration: {Acceptorder.TotalTime}</b>
                  </div>
                </div>
                <div className="ordiv5">
                  <div className="ordiv7">
                  <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}><b>Service  & cost</b></p>
                  </div>
                  {Acceptorder.services.map((service, index) => (
                    <div key={index}>
                      <div className="ordiv6">
                      <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}>{service.name}: {service.qty} ${(service.cost * (service.qty).toFixed(2))} </p>
                      {/* <p>{service.cost}</p> */}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="ordiv9">
                            {Acceptorder.status === 'Pending' ? (
                              <div>
                                <p className="text-muted mb-0 small"><b>Final Total :</b> ${Acceptorder.finalTotal}</p>
                                <b className="text-muted mb-0 small">Waiting for Service</b>
                              </div>
                            ) : (
                              <div>
                                <div className="ordiv8">
                                  <p className="text-muted mb-0 small"><b>Service Provider Name :</b>{Acceptorder.Providername}</p>
                                  <p className="text-muted mb-0 small"><b>Final Total :</b> ${Acceptorder.finalTotal}</p>
                                </div>
                                <b className="text-muted mb-0 small">Service is in Under Process</b>
                                {/* <Button onClick={() => handleCancelOrder(Acceptorder._id)}>Cancel</Button> */}
                              </div>
                            )}
                          </div>
                </div>
                
                
                
                
                ))}
                          
                      </MDBCardBody>
                    </MDBCard>
            </MDBCol>
              </MDBRow>
            </MDBContainer>














          </section>

        </div>


        )
}

        export default Vieworders
