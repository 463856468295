import React from 'react'
import './css/Home.css'
import { useNavigate } from 'react-router-dom';
import Homecau from './Homecau';

const Homimg = () => {
    // const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate();
  
    const handleButtonClick = () => {
     
        navigate('/Services');
      
    };
  
      
  return (
    <div className='hdiv1'>
        <div className="hdiv2">
          <div className="hdiv3">
            <h1 className='hh1'>
            Salon at your door in these locations:
            </h1> 
            <p className="hp1">
            Los Angeles | Las Vegas | Connecticut | Boston | Westchester | Charleston | Orange County
            </p>

          </div>
          <div className="hdiv4">

        <button onClick={handleButtonClick}>Book Now</button>
          </div>
        </div>
       <Homecau/>
    </div>
  )
}

export default Homimg