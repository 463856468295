import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from './logo.png';
import './Header.css';

import { Twitter } from '@mui/icons-material';

import Cartcop from '../Customer/Pages/Service/Cartcop';
import Instagram from '@mui/icons-material/Instagram';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, setCartItems } from '../Customer/Context/Action/actions'; // import your actions

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));


const Servicp = ({ checklogin }) => {
    const [expanded, setExpanded] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [displayname, setdisplayname] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to manage the drawer
    // const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart.cartItems);

    useEffect(() => {
        const fetchUserInfo = async () => {
          try {
            checklogin();
            const token = localStorage.getItem('token');
            if (token) {
              const decodedToken = jwtDecode(token);
              console.log(decodedToken);
              setUserRole(decodedToken.role);
              setdisplayname(decodedToken.displayName);
            }
          } catch (error) {
            console.error('Error fetching user information:', error);
          }
        };
    
        fetchUserInfo();
      }, [checklogin]);
    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartData'))?.cartItems || [];
        dispatch(setCartItems(storedCartItems));
      }, [dispatch]);
    
    const handleLinkClick = () => {
        setExpanded(false); // Close the navbar when a link is clicked
    };

    const logout = () => {
        window.open("https://homesalon.onrender.com/auth/logout", "_self");
        localStorage.removeItem('token');
        navigate("/");
    };

    const toggleDrawer = () => {
      handleLinkClick();
        setIsDrawerOpen(!isDrawerOpen);
    };
    // const handleAddToCart = (service) => {
    //     dispatch(addToCart(service));
    //     setIsDrawerOpen(true);
    // };

    // const handleRemoveFromCart = (index) => {
    //     dispatch(removeFromCart(index));
    // };
  const cart=cartItems
  console.log(cart,"inside service");
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top" expanded={expanded}>
                <div className="nava2">

                <Container >
                    <div className="con">

                    <Navbar.Brand href="#home">
                        <img src={logo} alt="" height={100} width={100} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    </div>
                    <div className="nava">
                        

                        <div className="navdiv2"> 
                            
                            <Link className="nav-link" onClick={toggleDrawer }>
                            <IconButton aria-label="cart">
      <StyledBadge badgeContent={cartItems.length} color="secondary">
        <ShoppingCartIcon style={{color: 'aliceblue'}}/>
      </StyledBadge>
    </IconButton>
                            </Link>
                        </div>

                        <div className="navdiv2">
                            <div className="navdiv3" >

                            <Link to='/myaccount' className="nav-link" onClick={handleLinkClick}>
                                <Nav>{displayname}</Nav>
                            </Link>
                            </div>
                            <div className="navdiv3">

                            <Link to='/Vieworders' className="nav-link" onClick={handleLinkClick}>
                                <Nav>Orders</Nav>
                            </Link>
                            </div>
                           
                        </div>
                  
                
             
                </div>
                        
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        <Link to='/' className="nav-link" onClick={handleLinkClick}>
                                <Nav>Home</Nav>
                            </Link>

                            <Link to='/Services' className="nav-link" onClick={handleLinkClick}>
                                <Nav>Service</Nav>
                            </Link>
                            <Link to='/aboutus' className="nav-link" onClick={handleLinkClick}>
                                <Nav>About Us</Nav>
                            </Link>
                            <Link to='/logout' className="nav-link" onClick={logout}>
                                <Nav>Logout</Nav>
                            </Link>
                            <Link to='https://www.instagram.com/' className="nav-link" onClick={handleLinkClick}>
                <Nav> <Instagram/> </Nav>
              </Link>
              <Link to='https://www.instagram.com/' className="nav-link" onClick={handleLinkClick}>
                <Nav> <Twitter/> </Nav>
              </Link>
               
                        </Nav>
                       
                    </Navbar.Collapse>
                </Container>
                </div>
             
            </Navbar>
            {/* Pass isOpen and onClose props to the CustomDrawer component */}
            <Cartcop isOpen={isDrawerOpen} onClose={toggleDrawer} cartItems={cartItems}  userRole={userRole} removeFromCart={removeFromCart}/>
        </div>
    );
}

export default Servicp;
