import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import Table from 'react-bootstrap/Table';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import './serviceselect.css';
import { useNavigate } from 'react-router-dom';

const Servicesselect = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
const navigator= useNavigate();
    const [serviceData, setServiceData] = useState({
        serviceName: '',
        serviceType: '',
        cost: '',
        time: '',
        productSuggestion: '',
        adminShare: ''  // State variable for admin share
    });

    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceNames, setServiceNames] = useState([]);
    const [userId, setUserId] = useState(null);
    const [fetchedServices, setFetchedServices] = useState([]); // State to store fetched services

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    console.log(decodedToken);
                    setUserId(decodedToken.id);
                }
            } catch (error) {
                console.error('Error fetching user information:', error);
            }
        };

        fetchUserInfo();

        const fetchServiceTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service/servicetypes`);
                setServiceTypes(response.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };

        fetchServiceTypes();
    }, [apiUrl]);

    useEffect(() => {
        const fetchServiceNames = async () => {
            if (serviceData.serviceType) {
                try {
                    const response = await axios.get(`${apiUrl}/service/services/by-type/${serviceData.serviceType}`);
                    setServiceNames(response.data.services);
                } catch (error) {
                    console.error('Error fetching service names:', error);
                }
            }
        };

        fetchServiceNames();
    }, [apiUrl, serviceData.serviceType]);

    useEffect(() => {
        if (!userId) return; // Exit early if userId is not set
        console.log("id",userId);
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service/services/${userId}`);
                console.log(response);
                setFetchedServices(response.data);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchServices();
    }, [apiUrl, userId]); // Runs whenever `apiUrl` or `userId` changes

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setServiceData((prevData) => ({
            ...prevData,
            [id]: files ? files[0] : value,
        }));

        if (id === 'serviceName') {
            const selectedService = serviceNames.find(service => service.serviceName === value);
            if (selectedService) {
                setServiceData((prevData) => ({
                    ...prevData,
                    adminShare: selectedService.Share
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('serviceName', serviceData.serviceName);
        formData.append('serviceType', serviceData.serviceType);
        formData.append('cost', serviceData.cost);
        formData.append('time', serviceData.time);
        formData.append('productSuggestion', serviceData.productSuggestion);
        formData.append('adminShare', serviceData.adminShare); // Include admin share
        formData.append('serviceProviderId', userId); // Include the service provider ID from the token

        try {
            const response = await axios.post(`${apiUrl}/service/spmainservice`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response);
            alert('Service added successfully');
            setServiceData({
                serviceName: '',
                serviceType: '',
                cost: '',
                time: '',
                productSuggestion: '',
                adminShare: '' // Reset admin share
            });
        } catch (error) {
            alert(`Service Name Already Inserted: ${error}`);
            console.error('Error adding service:', error);
        }
    };

    return (
        <div className="admindiv1">
            <div className="admindiv2">
              <Button  onClick={()=>{navigator('/sp-service')}}>Add Service</Button>
                {/* Table to display fetched services */}
                    <h2 style={{ textAlign: 'center' }}>Service List</h2>
                <div className="table-container">
                    <Table  responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Service Type</th>
                                <th>Cost</th>
                                <th>Time</th>
                                <th>Product Suggestion</th>
                                <th>Admin Share</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedServices.map((service) => (
                                <tr key={service._id}>
                                    <td>{service.serviceName}</td>
                                    <td>{service.serviceType}</td>
                                    <td>{service.cost}</td>
                                    <td>{service.time}</td>
                                    <td>{service.productSuggestion}</td>
                                    <td>{service.adminShare}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default Servicesselect;
