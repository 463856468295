import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from 'react';
import './order.css'
import { Button } from "react-bootstrap";
import { jwtDecode } from 'jwt-decode';
import { addToCart, removeFromCart } from '../../Context/Action/actions'; // import your actions
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Cartcop from "../../Pages/Service/Cartcop";
import Loading from '../../../Loading'

const Orders = ({ checklogin }) => {
  const [cid, setCid] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [selectedTab, setSelectedTab] = useState('upcoming');
  const [acceptOrder, setAcceptOrder] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cartItems);
  const apiUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        checklogin();
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setDisplayName(decodedToken.displayName);
          setCid(decodedToken.id);
          setUserRole(decodedToken.role);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, [checklogin]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/order/orders-by-customer/status/${cid}`);
        // Filter out canceled orders
        const filteredOrders = response.data.filter(order => order.status !== 'Cancel');
        setAcceptOrder(filteredOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    if (cid) fetchOrders();
  }, [cid]);

  useEffect(() => {
    const fetchServiceProviders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/order/orders-by-customer/Completed/${cid}`);
        setServiceProviders(response.data);
      } catch (error) {
        console.error('Error fetching completed orders:', error);
      } finally {
        setLoading(false);
      }
    };

    if (cid) fetchServiceProviders();
  }, [cid]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleAddToCart = (service) => {
    dispatch(addToCart(service));
    setIsDrawerOpen(true);
  };

  const handleRemoveFromCart = (index) => {
    dispatch(removeFromCart(index));
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCancelOrder = async (orderId) => {
    const confirmCancel = window.confirm("Are you sure you want to cancel this order?");
    if (!confirmCancel) {
      return;
    }

    try {
      await axios.patch(`${apiUrl}/order/update-order-cust/${orderId}`);
      setAcceptOrder((prevOrders) => prevOrders.filter(order => order._id !== orderId));
      
      const canceledOrder = acceptOrder.find(order => order._id === orderId);
      if (canceledOrder) {
        setServiceProviders((prevOrders) => [...prevOrders, { ...canceledOrder, status: 'Cancel' }]);
      }
      alert("Order is Cancelled");
    } catch (error) {
      console.error('Error cancelling order:', error);
    }
  };

  return (
    <div className="Div1">
      <section className="h-100 gradient-custom" style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center-1 h-100">
            <MDBCol lg="10" xl="8">
              <MDBCard style={{ borderRadius: "10px" }}>
                <MDBCardHeader className="px-4 py-5 card">
                  <MDBTypography tag="h5" className="text-muted mb-0">
                    Here are your orders, <span style={{ color: "#a8729a" }}>{displayName}</span>!
                  </MDBTypography>
                  <ul className="nav nav-tabs">
                    <li className="nav-item ordrli">
                      <Link
                        className={`nav-links ${selectedTab === 'upcoming' ? 'active' : ''}`}
                        onClick={() => handleTabChange('upcoming')}
                      >
                        Upcoming Orders
                      </Link>
                    </li>
                    <li className="nav-item ordrli">
                      <Link
                        className={`nav-links ${selectedTab === 'past' ? 'active' : ''}`}
                        onClick={() => handleTabChange('past')}
                      >
                        Past Orders
                      </Link>
                    </li>
                  </ul>
                </MDBCardHeader>
                <MDBCardBody>
                  {loading ? (
                    <div><Loading/></div>
                  ) : selectedTab === 'upcoming' ? (
                    <div style={{ marginTop: "17px" }}>
                      <h3>Upcoming Orders</h3>
                      {acceptOrder.length === 0 ? (
                        <div>You don't have any upcoming orders.</div>
                      ) : (
                        acceptOrder.map((order) => (
                          <div className="ordiv1" key={order.id}>
                            <div className="ordiv2">
                              <div className="ordiv3">
                                <div>
                                  {/* <b style={{ color: "rgb(168, 114, 154)" }}>{order.Time}</b> */}
                                </div>
                                <div>
                                  <b style={{ fontSize: "2em", color: "rgb(168, 114, 154)" }}>
                                    {new Date(order.Bdate).toLocaleDateString('en-US', {
                                      day: 'numeric',
                                      month: 'long',
                                      year: 'numeric'
                                    }).replace(',', '')}
                                  </b>
                                </div>
                              </div>
                              <div className="ordiv4">
                                <b style={{ color: "rgb(168, 114, 154)" }}>
                                  At {order.Time}</b>
                                <b style={{ color: "rgb(168, 114, 154)" }}>
                                  Duration: {order.TotalTime}</b>
                              </div>
                            </div>
                            <div className="ordiv5">
                              <div className="ordiv7">
                                <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}><b>Service  & cost</b></p>
                              </div>
                              {order.services.map((service, index) => (
                                <div key={index}>
                                  <div className="ordiv6">
                                    <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}>{service.name}: {service.qty} ${(service.cost * (service.qty).toFixed(2))} </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="ordiv9">
                              {order.status === 'Pending' ? (
                                <div>
                                  <p className="text-muted mb-0 small"><b>Total Cost: </b> ${order.finalTotal}</p>
                                  <b className="text-muted mb-0 small">Waiting for Service</b>
                                </div>
                              ) : (
                                <div>
                                  <div className="ordiv8">
                                    <p className="text-muted mb-0 small"><b>Service Provider: </b>{order.Providername}</p>
                                    <p className="text-muted mb-0 small"><b>Total Cost: </b> ${order.finalTotal}</p>
                                  </div>
                                  <div className="orderdiv">
                                    <b className="text-muted mb-0 small">Service is in Under Process</b>
                                    <Button onClick={() => handleCancelOrder(order._id)} style={{ position: 'relative', left: "30%" }}>Cancel services</Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  ) : (
                    <div>
                      <h3>Past Orders</h3>
                      {serviceProviders.length === 0 ? (
                        <div>You don't have any past orders.</div>
                      ) : (
                        serviceProviders.map((serviceprovider) => (
                          <div className="ordiv1" key={serviceprovider.id}>
                            <div className="ordiv2">
                              <div className="ordiv3">
                                <div>
                                  {/* <b style={{ color: "rgb(168, 114, 154)" }}>{order.Time}</b> */}
                                </div>
                                <div>
                                  <b style={{ fontSize: "2em", color: "rgb(168, 114, 154)" }}>
                                    {new Date(serviceprovider.Bdate).toLocaleDateString('en-US', {
                                      day: 'numeric',
                                      month: 'long',
                                      year: 'numeric'
                                    }).replace(',', '')}
                                  </b>
                                </div>
                              </div>
                              <div className="ordiv4">
                                <b style={{ color: "rgb(168, 114, 154)" }}>
                                  At {serviceprovider.Time}</b>
                                <b style={{ color: "rgb(168, 114, 154)" }}>
                                  Duration: {serviceprovider.TotalTime}</b>
                              </div>
                            </div>
                            <div className="ordiv5">
                              <div className="ordiv7">
                                <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}><b>Service  & cost</b></p>
                              </div>
                              {serviceprovider.services.map((service, index) => (
                                <div key={index}>
                                  <div className="ordiv6">
                                    <p className="text-muted mb-0" style={{ listStyle: 'none', paddingBottom: '4px' }}>{service.name}: {service.qty}  ${(service.cost * (service.qty).toFixed(2))}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="ordiv9">
                              {serviceprovider.status === 'Pending' ? (
                                <div>
                                  <div>
                                    <p className="text-muted mb-0 small"><b>Total Cost: </b> ${serviceprovider.finalTotal}</p>
                                    <b className="text-muted mb-0 small">Waiting for Service</b>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="ordiv8">
                                    <p className="text-muted mb-0 small"><b>Service Provider Name :</b>{serviceprovider.Providername}</p>
                                    <p className="text-muted mb-0 small"><b>Final Total :</b> ${serviceprovider.finalTotal}</p>
                                  </div>
                                  <b className="text-muted mb-0 small">Order is {serviceprovider.status}</b>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      
      <Cartcop 
        isOpen={isDrawerOpen} 
        onClose={toggleDrawer} 
        cartItems={cartItems}  
        userRole={userRole} 
        removeFromCart={handleRemoveFromCart} 
      />
    </div>
  );
};

export default Orders;
