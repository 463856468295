import React from 'react'
import './About.css'

import img from '../../Componenet/image/city2.png'
import img1 from '../../Componenet/image/down2.png'
import img2 from '../../Componenet/image/rate2.png'
import img3 from '../../Componenet/image/help2.svg'
import img4 from '../../Componenet/image/feedback2.png'
import img5 from '../../Componenet/image/3001.png'
const About = () => {
  return (
    <div className='adiv1'>
      <h1>Who We Are</h1>
      <p>
    Welcome to [Your Home Salon's Name], India’s premier home salon service, founded in [Year] and currently serving clients in over 50 cities across the country. Our mission is to bring high-quality salon and wellness experiences directly to your doorstep, delivered by skilled professionals using top-tier products.

At [Your Home Salon's Name], we pride ourselves on offering more than just traditional salon services.
</p>
<p>
From rejuvenating therapies and massages to luxurious spa treatments, LED facials, male grooming, and keratin hair spas, we provide a comprehensive range of beauty and wellness services tailored to your convenience.

Our commitment to convenience extends to everyone, regardless of gender.
</p>
<p>
  Men can also enjoy the ease and comfort of grooming and spa services right at home.

We believe in making luxury accessible to all. Our services start at just Rs. 6 per minute, ensuring affordability without compromising on quality. With [Your Home Salon's Name], you can indulge in premium salon experiences at unbeatable prices.
 
</p>
<p>

Safety is our top priority. Our professionals adhere to a stringent 13-step safety protocol to ensure your well-being. Additionally, we maintain strict hygiene standards and use only sealed, one-time use products to guarantee your safety and peace of mind. We are transparent about the products we use, and clients are welcome to provide their own if they prefer.

Experience the convenience and luxury of salon services in the comfort of your own home with [Your Home Salon's Name]. Book your appointment today and let our expert professionals pamper you to perfection.
</p>
  
     
      <div className="adiv2">
          <div className="adiv3">
              <img src={img} alt="" srcset=""  className='adiv4'/>
          </div>
          <div className="adiv3">
              <img src={img1} alt="" srcset=""  className='adiv4'/>
          </div>
          <div className="adiv3">
              <img src={img2} alt="" srcset=""  className='adiv4'/>
          </div>
          <div className="adiv3">
              <img src={img3} alt="" srcset="" className='adiv4'/>
          </div>
          <div className="adiv3">
              <img src={img4} alt="" srcset="" className='adiv4'/>
          </div>
          <div className="adiv3">
              <img src={img5} alt="" srcset=""  className='adiv4'/>
          </div>
      </div>
      <p>Now that we live in the 21st Century, we can not only shop for groceries from the comfort of our homes but also book a professional to get beauty and salon services at home.</p>
   
    </div>
  )
}

export default About
