import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import './serviceselect.css';
import { useNavigate } from 'react-router-dom';

const Addservicesp = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [serviceData, setServiceData] = useState({
        serviceName: '',
        serviceType: '',
        cost: '',
        time: '',
        productSuggestion: '',
        adminShare: ''  // State variable for admin share
    });

    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceNames, setServiceNames] = useState([]);
    const [userId, setUserId] = useState(null);
    const [fetchedServices, setFetchedServices] = useState([]); // State to store fetched services
    const navigator = useNavigate();
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    console.log(decodedToken);
                    setUserId(decodedToken.id);
                }
            } catch (error) {
                console.error('Error fetching user information:', error);
            }
        };

        fetchUserInfo();

        const fetchServiceTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service/servicetypes`);
                setServiceTypes(response.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };

        fetchServiceTypes();
    }, [apiUrl]);

    useEffect(() => {
        const fetchServiceNames = async () => {
            if (serviceData.serviceType) {
                try {
                    const response = await axios.get(`${apiUrl}/service/services/by-type/${serviceData.serviceType}`);
                    setServiceNames(response.data.services);
                } catch (error) {
                    console.error('Error fetching service names:', error);
                }
            }
        };

        fetchServiceNames();
    }, [apiUrl, serviceData.serviceType]);

    useEffect(() => {
        if (!userId) return; // Exit early if userId is not set
        console.log("id",userId);
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service/services/${userId}`);
                console.log(response);
                setFetchedServices(response.data);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchServices();
    }, [apiUrl, userId]); // Runs whenever `apiUrl` or `userId` changes

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setServiceData((prevData) => ({
            ...prevData,
            [id]: files ? files[0] : value,
        }));

        if (id === 'serviceName') {
            const selectedService = serviceNames.find(service => service.serviceName === value);
            if (selectedService) {
                setServiceData((prevData) => ({
                    ...prevData,
                    adminShare: selectedService.Share
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('serviceName', serviceData.serviceName);
        formData.append('serviceType', serviceData.serviceType);
        formData.append('cost', serviceData.cost);
        formData.append('time', serviceData.time);
        formData.append('productSuggestion', serviceData.productSuggestion);
        formData.append('adminShare', serviceData.adminShare); // Include admin share
        formData.append('serviceProviderId', userId); // Include the service provider ID from the token

        try {
            const response = await axios.post(`${apiUrl}/service/spmainservice`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response);
            alert('Service added successfully');
            navigator("/Service")
            setServiceData({
                serviceName: '',
                serviceType: '',
                cost: '',
                time: '',
                productSuggestion: '',
                adminShare: '' // Reset admin share
            });
        } catch (error) {
            alert(`Service Name Already Inserted: ${error}`);
            console.error('Error adding service:', error);
        }
    };
  return (
    <div>
         <div className="admindiv1">
            <div className="admindiv10">
                <h1 style={{ textAlign: 'center' }}>Add Service</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Col} controlId="serviceType">
                        <Form.Label>Service Type</Form.Label>
                        <Form.Control as="select" value={serviceData.serviceType} onChange={handleChange}>
                            <option value="">Select Service Type</option>
                            {serviceTypes.map((type) => (
                                <option key={type._id} value={type.servicetype}>
                                    {type.servicetype}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="serviceName">
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control as="select" value={serviceData.serviceName} onChange={handleChange}>
                            <option value="">Select Service Name</option>
                            {serviceNames.map((name) => (
                                <option key={name._id} value={name.serviceName}>
                                    {name.serviceName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control placeholder="Enter Cost" value={serviceData.cost} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="time">
                        <Form.Label>Time</Form.Label>
                        <Form.Control placeholder="Require Time for service" value={serviceData.time} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="productSuggestion">
                        <Form.Label>Product Suggestion</Form.Label>
                        <Form.Control placeholder="Product Suggestion" value={serviceData.productSuggestion} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="adminShare">
                        <Form.Label>Admin Share</Form.Label>
                        <Form.Control placeholder="Admin Share" value={serviceData.adminShare} onChange={handleChange} readOnly />
                    </Form.Group>
                    <div className="frmdiv5">
                        <Button style={{ width: "200px" }} variant="primary" type="submit">
                            Submit
                        </Button>
                        <Button style={{ width: "200px" }} variant="primary" type="button">
                            Cancel
                        </Button>
                    </div>
                </Form>

               
            </div>
        </div>
    </div>
  )
}

export default Addservicesp