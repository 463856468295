import React from 'react'
import Homimg from '../Componenet/Home/Homimg'
import Homeaboutus from '../Componenet/Home/Homeaboutus'
import './homep.css'
import Contactus from '../Componenet/Home/Contactus'
import Testminal from '../Componenet/Home/Testminal'
// import Homeslide from '../Componenet/Home/Homeslide'
const Home = () => {
  return (
    <div className='hdiv'>
      <div className='Home'>

        <Homimg/> 
        {/* <Homeslide/> */}
      </div>
        <Homeaboutus/>
        <Contactus/>
        <Testminal/>
    </div>
  )
}

export default Home