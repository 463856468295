import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
const Serviceproviders = () => {
    const [serviceProviders, setserviceProviders] = useState([]);

    useEffect(() => {
        const fetchsp = async () => {
          try {
            const response = await axios.get('https://homesalon.onrender.com/register/serviceproviders/service');
            console.log(response.data);
            setserviceProviders(response.data);
            // setCities(response.data);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchsp();
      }, []);
      console.log(serviceProviders);
      if (!serviceProviders.length) {
        return <div>Loading...</div>;
      }
    
  return (
    <div style={{marginTop:"150px"}}>
         <div>
      <h1>Service Provider Details</h1>
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Email</th>
            <th>Role</th>
            {/* <th>Address</th> */}
            <th>Assigned Services</th>
            <th>Availability</th>
            <th>Zip Code</th>
          </tr>
        </thead>
        <tbody>
          {serviceProviders.map(serviceProvider => (
            <tr key={serviceProvider._id}>
              <td>{serviceProvider.displayName}</td>
              <td>{serviceProvider.email}</td>
              <td>{serviceProvider.role}</td>
              {/* <td>{serviceProvider.addresses ? serviceProvider.addresses.join(', ') : 'No addresses available'}</td> */}
              <td>
                {serviceProvider.assignedServices && serviceProvider.assignedServices.length > 0 ? (
                  serviceProvider.assignedServices.map(service => (
                    <div key={service._id}>
                      {service.serviceName}
                    </div>
                  ))
                ) : (
                  'No assigned services available'
                )}
              </td>
              <td>
                {serviceProvider.avaliblity && serviceProvider.avaliblity.length > 0 ? (
                  serviceProvider.slotIds.map(slot => (
                    <div key={slot._id}>
                      {slot.day}: {slot.startTime} - {slot.endTime}
                    </div>
                  ))
                ) : (
                  'No availability slots available'
                )}
              </td>
              <td>
                {serviceProvider.assignedZipCodes && serviceProvider.assignedZipCodes.length > 0 ? (
                  serviceProvider.assignedZipCodes.join(', ')
                ) : (
                  'No zip codes available'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    </div>
  )
}

export default Serviceproviders
