import React  from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';
import Cart from '../Cart/Cart';

const Cartcop = ({ isOpen, onClose, cartItems, removeFromCart, userRole }) => {
  
    console.log(cartItems,"inside cartcop");
    const handleCloseClick = () => {
        console.log("buttonclick");
        onClose();
    };
    console.log('cartcop',userRole);
    
        return (
            <Drawer open={isOpen} anchor='right' onClose={onClose}>
                <Cart cartItems={cartItems}  removeFromCart={removeFromCart} userRole={userRole} onClose={handleCloseClick}  />
                <div className="cardiv8" onClick={()=>{handleCloseClick()}} ><CloseIcon/></div>
            </Drawer>
        );
       
    
}

export default Cartcop;
