import React, { useEffect, useState } from 'react';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import './myaccount.css';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';// Corrected import statement for jwtDecode

const Profile = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(null); // Renamed userdetail to userDetail and initialized to null
  // const [isLoading, setIsLoading] = useState(true); // Added isLoading state

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const resObject = await response.json();
          console.log(resObject);
          setUserDetail(resObject.user);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      } 
      // finally {
      //   setIsLoading(false); // Set loading state to false regardless of success or failure
      // }
    };

    getUser();
    
    const fetchUserInfo = () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserDetail(decodedToken);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>; // Show loading indicator while fetching user info
  // }

  return (
    <div className='prmain'>
      <h1 className='prh1'>ACCOUNT</h1>
      {userDetail && ( // Conditionally render if userDetail is available
        <h6 className='prh6'>{userDetail.displayName} {userDetail.email}</h6>
      )}
      <div className="prdiv1">
        <div className="prcard" onClick={() => {navigate('/personalinfo')}}>
          <BadgeOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>Personal Info</h6>
          <p>Provide personal details</p>
        </div>
        <div className="prcard" onClick={() => {navigate('/logininfo')}}>
          <AdminPanelSettingsOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>Login & Security</h6>
          <p>Update your password and secure your account</p>
        </div>
        <div className="prcard">
          <CreditCardOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>Payment Method</h6>
          <p>Review Payment</p>
        </div>
        <div className="prcard">
          <InsertDriveFileOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>Liability</h6>
          <p>Provide personal details</p>
        </div>
        <div className="prcard" onClick={() => {navigate('/Address')}}>
          <AddHomeWorkOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>Add Address</h6>
          <p>Provide personal details</p>
        </div>
        <div className="prcard" onClick={() => {navigate('/Vieworders')}}>
          <CardGiftcardOutlinedIcon sx={{ fontSize: 50 }}/>
          <h6>View Order</h6>
          <p>Provide personal details</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;
