import Header from "./Common/Header";
import Loadingpage from './Customer/Pages/book/Loadingpage'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Customer/Pages/Home";
// import Service from "./Customer/Pages/Service/Service";
import Cart from "./Customer/Pages/Cart/Cart";
import Billing from "./Customer/Pages/Bill/Billing";
import Order from "./Customer/Pages/orderconfirmation/Order";
// import AppointmentProgress from "./Customer/Pages/orderconfirmation/AppointmentProgress";
import Myaccount from "./Customer/Pages/myaccount/Myaccount";
import About from "./Customer/Pages/about/About";
import Oservice from "./Customer/Pages/Service/Oservice";
import Profiledetail from "./Customer/Componenet/myaccoutnts/Profiledetail";
import Loginedit from "./Customer/Componenet/myaccoutnts/Loginedit";
// import Address from "./Customer/Componenet/myaccoutnts/Address";
// import Vieworder from "./Customer/Componenet/myaccoutnts/Vieworder";
import Login from "./Customer/Pages/login/Login";
import { useEffect, useState } from "react";
import { jwtDecode } from 'jwt-decode';
import Register from "./Customer/Pages/login/Register";
import { AuthProvider } from "./Auth/store";
import Servicp from "./Common/Servicp";
import Cartcop from "./Customer/Pages/Service/Cartcop";
import Orders from "./Customer/Componenet/myaccoutnts/Orders";
// import Navbars from "./Common/Navbars";
import Addresses from "./Customer/Componenet/Addresscheckout/Addresses";
import BookForm from "./Customer/Pages/book/BookForm";
import AddAddress from "./Customer/Componenet/myaccoutnts/AddAddress";
import Addressview from "./Customer/Componenet/myaccoutnts/Address/Addressview";
import Loginupdate from "./Customer/Componenet/myaccoutnts/update/Loginupdate";
import Vieworders from "./Customer/Componenet/myaccoutnts/Vieworders";
import Serviceprovider from "./Common/serviceprovider/Serviceprovider";
import SpAvalablity from "./sp/Page/SpAvalablity";
import Servicesselect from "./sp/Page/Services/Servicesselect";
import Spzipcode from "./sp/Page/Spzipcode";
// import Sporder from "./sp/Page/Sporder";
import MyCalendar from "./sp/Page/MyCalendar";
import Adminnav from "./Common/Adminheader/Adminnav";
import Addservices from "./Admin/Addservices/Addservices";
import Serviceproviders from "./Admin/Addservices/Serviceproviders";
import Customerdetails from "./Admin/Addservices/Customerdetails";
import Adminedit from "./Admin/Addservices/Adminedit";
import Adminorder from "./Admin/Addservices/Adminorder";
import Rejection from "./sp/Page/Rejection";
// import SpHomeimg from "./Customer/Componenet/Home/SpHomeimg";
import HomeSp from "./Customer/Pages/HomeSp";
import Addressupdate from "./Customer/Componenet/myaccoutnts/update/Addressupdate";
import CustomerFeedback from './Admin/Addservices/CustomerFeedback'
import AddSubservices from "./Admin/Addservices/AddSubservices";
import Openorder from "./sp/Page/Openorder";
import Adminpayment from "./Admin/Addservices/Adminpayment";
import Paymentassign from "./sp/Page/Paymentassign";
import Addsubservice from "./Admin/Addservices/Addsubservice";
import Addservicess from "./Admin/Addservices/Addservicess";
import AddZipcode from "./Admin/Addservices/AddZipcode";
import Addservicesp from "./sp/Page/Services/Addservicesp";

function App() {
  // const [user, setUser] = useState(null);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);


    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [display, setdisplay] = useState('');
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    // const getUser = async () => {
    //     try {
    //         const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
    //             method: "GET",
    //             credentials: "include",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //             },
    //         });
    //         const token = localStorage.getItem('token');
    //         if (response.status === 200) {
    //             const resObject = await response.json();
    //             // setIsLoggedIn(true);
    //             setUserRole(resObject.user.role);
    //             setdisplay(resObject.user.displayName);
    //         } else {
    //             throw new Error("Authentication failed");
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    // getUser();

    const fetchUserInfo = async () => {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            const decodedToken = jwtDecode(token);
            setIsLoggedIn(true);
            setUserRole(decodedToken.role);
            setdisplay(decodedToken.displayName);
          }
        } catch (error) {
          console.error('Error fetching user information:', error);
        }
      };
  
      fetchUserInfo();
  }, []);

  const checklogin = () => {
      try {
          console.log("checklogin");
          const token = localStorage.getItem('token');
          if (token) {
              const decodedToken = jwtDecode(token);

              console.log(decodedToken);
              setIsLoggedIn(true);
              setUserRole(decodedToken.role);
          }
      } catch (error) {
          console.error('Error fetching user information:', error);
      }
  };
  const handleLogout = () => {
      localStorage.removeItem('token');
      // navigate("/");
      setIsLoggedIn(false);
      setUserRole('null');
  };
  console.log(isLoggedIn);
  console.log(userRole);
  // console.log(user);
  return (
    <div className="App">
        <Router>
            <AuthProvider>
            {!isLoggedIn && (
  <>
    {/* <Top /> */}
    <Header checklogin={checklogin} />
  </>
)}
                    { userRole==='customer' && <Servicp handleLogout={handleLogout} checklogin={checklogin}/>}
                    { userRole==='Service Provider' && <Serviceprovider handleLogout={handleLogout} checklogin={checklogin}/>}
                    { userRole==='Admin' && <Adminnav handleLogout={handleLogout} checklogin={checklogin}/>}
                    {/* {isLoggedIn && !isAdmin && <Studentnav handleLogout={handleLogout} />} */}
            
 
            <Routes>
            <Route exact path='/' element={userRole === 'Service Provider' ? <HomeSp /> : <Home />} />

                <Route path='/Avaliblity' element={<Loadingpage/>}/>
                <Route path='/Services' element={<Oservice userRole={userRole}/>}/>
                <Route path='/Cart' element={<Cart userRole={userRole} checklogin={checklogin} />} />

                <Route path='/Bill' element={<Billing userRole={userRole} display={display} checklogin={checklogin}/>}/>
                <Route path='/confirmation' element={<Order/>}/>
                <Route path='/myaccount' element={<Myaccount/>}/>
                <Route path='/aboutus' element={<About/>}/>
                <Route path='/personalinfo' element={<Profiledetail userRole={userRole}/>}/>
                <Route path='/logininfo' element={<Loginedit/>}/>
                <Route path='/Address' element={<Addressview userRole={userRole} checklogin={checklogin}/>}/>
                <Route path='/Address-Checkout' element={<Addresses userRole={userRole} checklogin={checklogin}/>}/>
                <Route path='/Vieworders' element={<Orders  checklogin={checklogin} userRole={userRole}/>}/>
                <Route path='/Vieworder' element={<Vieworders  checklogin={checklogin} userRole={userRole}/>}/>
                <Route path='/login' element={<Login checklogin={checklogin} toggleDrawer={toggleDrawer}/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path="/bill/:provider/:time/:date" element={<Billing />} />
                <Route path="/addtocart" element={<Cartcop />} />
                <Route path="/Addrequest" element={<BookForm />} />
                <Route path="/Addaddress" element={<AddAddress />} />
                <Route path="/user-update" element={<Loginupdate />} />
                <Route path="/Availability" element={<SpAvalablity />} />
                <Route path="/Service" element={<Servicesselect checklogin={checklogin} userRole={userRole} />} />
                <Route path="/Zipcode" element={<Spzipcode />} />
                <Route path="/sp-order" element={<MyCalendar />} />
                <Route path="/Open-Order" element={<Openorder />} />
                <Route path="/add-service" element={<AddSubservices />} />
                <Route path="/service-name" element={<Addservicess/>} />
                <Route path="/Serviceproviders" element={<Serviceproviders />} />
                <Route path="/Customer" element={<Customerdetails />} />
                <Route path="/CSS" element={<Adminedit />} />
                <Route path="/view-order" element={<Adminorder />} />
                <Route path="/Rejected" element={<Rejection />} />
                <Route path="/Addressupdate" element={<Addressupdate />} />
                <Route path="/AdminReview" element={<CustomerFeedback />} />
                <Route path="/subservice" element={<Addservices />} />
                <Route path="/Add-PaymentMethod" element={<Adminpayment />} />
                <Route path="/assign-PaymentMethod" element={<Paymentassign />} />
                <Route path="/Addsubservice" element={<Addsubservice />} />
                <Route path="/Admin-Zipcode" element={<AddZipcode />} />
                <Route path="/sp-service" element={<Addservicesp />} />

                
            </Routes>
         
          
            </AuthProvider>
        </Router>
    </div>
  );
}

export default App;
