import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearCartItems } from '../../Context/Action/actions';
import './bill.css';
import { useDispatch, useSelector } from 'react-redux';

const Billing = () => {
    const [comment, setComment] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const cartItems = useSelector(state => state.cart.cartItems);
    const location = useLocation();
    const { selectedProviderDisplayName, selectedAvailability, selectedDate, selectedAddress, selectedServiceProvider, cid, services, paymentMethods,quantities } = location.state;
    const apiUrl = process.env.REACT_APP_API_URL;
    const formattedDate = `${selectedDate.$M + 1}/${selectedDate.$D}/${selectedDate.$y}`;
console.log(quantities);
    useEffect(() => {
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 1);
        localStorage.setItem('cartData', JSON.stringify({ cartItems, expirationDate }));
    }, [cartItems]);
    console.log(quantities);

    const clearAllOrderItems = async () => {
        const orderData = {
            customerId: cid,
            serviceProviderId: selectedServiceProvider,
            cname: `${selectedAddress.fname} ${selectedAddress.lname}`,
            Address: `${selectedAddress.Al1} ${selectedAddress.Al2} ${selectedAddress.city} ${selectedAddress.state} ${selectedAddress.zipcode}`,
            Comment: comment,
            services: services.map((item, index) => ({
                name: item.serviceName,
                cost: item.cost,
                duration: item.time,
                share: item.share,
                quantity: quantities[index] || 1
            })),
            Bdate: formattedDate,
            Providername: selectedProviderDisplayName,
            Time: selectedAvailability.startTime,
            status: 'accepted',
            discount: '0',
            paymentMethod: selectedPaymentMethod,
            paymentId: "12345",
        };

        try {
            const response = await fetch(`${apiUrl}/order/create-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error ${response.status}: ${errorText}`);
                if (response.status === 400) {
                    alert("Please select Other Slot");
                }
                if(response.status===500){
                    alert("Please select payment method");
                }
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const data = await response.json();
            console.log('Success:', data);
            navigate('/Vieworder', { state: { ...location.state } });
            dispatch(clearCartItems());
        } catch (error) {
            console.error('Error:', error);
        }
    };
console.log(paymentMethods);
const subtotal = services.reduce((acc, item, index) => acc + item.cost * (quantities[index] || 1), 0);
const taxRate = 0.1; // Example tax rate of 10%
// const tax = subtotal * taxRate;
// const total = subtotal + tax;

    const goBack = () => {
        navigate(-1); // Navigate back in the browser history
    };

    return (
        <div className="bdiv3">
            <div className="billdiv1">
                <h1 style={{ textAlign: 'center' }}>Your Information</h1>

                <div className="bdiv4">
                    <b>Name: <span>{selectedAddress.fname} {selectedAddress.lname}</span></b>
                </div>
                <div className="bdiv4">
                    <b>Address: <span>{selectedAddress.Al1} {selectedAddress.Al2} {selectedAddress.city} {selectedAddress.state} {selectedAddress.zipcode}</span></b>
                </div>
                <div className="bdiv4">
                    Add Comment: <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                </div>
                <div className="bdiv4">
                    <b>Payment Method: </b>
                    <select value={selectedPaymentMethod} onChange={(e) => setSelectedPaymentMethod(e.target.value)}>
                        <option value="">Select Payment Method</option>
                        {paymentMethods.map((method, index) => (
                            <option key={index} value={method}>{method}</option>
                        ))}
                    </select>
                </div>
                <Button onClick={goBack}>Cancel</Button>
                <Button onClick={clearAllOrderItems}>Confirm Order</Button>
            </div>
            <div className='bsdiv'>
                <div className='bdiv5'>
                    <h2>Service Details</h2>
                    <div className="bdiv1">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Service Name</th>
                                    <th>Qty</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {services.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.serviceName}</td>
                                        <td>{quantities[index] || 1}</td>
                                        <td>${(item.cost * (quantities[index] || 1)).toFixed(2)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3}> Total</td>
                                    <td>${subtotal.toFixed(2)}</td>
                                </tr>
                                {/* <tr>
                                    <td colSpan={2}>Tax</td>
                                    <td>${tax.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Total</td>
                                    <td>${total.toFixed(2)}</td>
                                </tr> */}
                            </tbody>
                        </Table>
                        <h6>Book Time: {formattedDate} {selectedAvailability.startTime}</h6>
                        <h6>Provider Name: {selectedProviderDisplayName}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
