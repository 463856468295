import { ADD_TO_CART, REMOVE_FROM_CART, TOGGLE_CART_DRAWER, SET_CART_ITEMS, CLEAR_CART_ITEMS, INCREMENT_QUANTITY, DECREMENT_QUANTITY } from './actionType';

const initialState = {
  cartItems: [],
  isDrawerOpen: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case ADD_TO_CART: {
      const existingItemIndex = state.cartItems.findIndex(
        (cartItem) => cartItem.service._id === action.payload.service._id
      );

      let updatedCartItems;
      if (existingItemIndex >= 0) {
        updatedCartItems = state.cartItems.map((cartItem, index) =>
          index === existingItemIndex
            ? { ...cartItem, service: { ...cartItem.service, quantity: cartItem.service.quantity + 1 } }
            : cartItem
        );
      } else {
        const newItem = { ...action.payload, service: { ...action.payload.service, quantity: 1 } };
        updatedCartItems = [...state.cartItems, newItem];
      }

      const updatedCartData = {
        expirationDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000), // Set expiration date to 2 days from now
        cartItems: updatedCartItems,
      };
      localStorage.setItem('cartData', JSON.stringify(updatedCartData));
      return {
        ...state,
        cartItems: updatedCartItems,
      };
    }
    case REMOVE_FROM_CART: {
      const newCartItems = state.cartItems.filter((_, index) => index !== action.payload);
      localStorage.setItem('cartData', JSON.stringify({ ...state, cartItems: newCartItems }));
      return {
        ...state,
        cartItems: newCartItems,
      };
    }
    case CLEAR_CART_ITEMS:
      localStorage.removeItem('cartData'); // Clear only cart items from local storage
      return {
        ...state,
        cartItems: [],
      };
    case INCREMENT_QUANTITY: {
      const updatedCartItems = state.cartItems.map((item, index) =>
        index === action.payload ? { ...item, service: { ...item.service, quantity: item.service.quantity + 1 } } : item
      );
      localStorage.setItem('cartData', JSON.stringify({ ...state, cartItems: updatedCartItems }));
      return {
        ...state,
        cartItems: updatedCartItems,
      };
    }
    case DECREMENT_QUANTITY: {
      const updatedCartItems = state.cartItems.map((item, index) =>
        index === action.payload
          ? { ...item, service: { ...item.service, quantity: item.service.quantity > 1 ? item.service.quantity - 1 : 1 } }
          : item
      );
      localStorage.setItem('cartData', JSON.stringify({ ...state, cartItems: updatedCartItems }));
      return {
        ...state,
        cartItems: updatedCartItems,
      };
    }
    case TOGGLE_CART_DRAWER:
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    default:
      return state;
  }
};

export default cartReducer;
