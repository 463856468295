import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'; // for custom styles
import {jwtDecode} from 'jwt-decode'; // Corrected import statement
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function MyCalendar() {
  const [date, setDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sid, setSid] = useState('');
  const [availability, setAvailability] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          console.log(decodedToken);
          setSid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  console.log(sid);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        console.log("sid", sid);
        const response = await fetch(`https://homesalon.onrender.com/order/orders-by-service-provider/${sid}`); // Replace with your API endpoint
        const data = await response.json();
        console.log(data);
        setOrders(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setOrders([]); // Ensure orders is always an array
      }
      setLoading(false);
    };

    const fetchAvailability = async () => {
      try {
        const response = await fetch(`https://homesalon.onrender.com/availability/${sid}`); // Replace with your API endpoint
        const data = await response.json();
        console.log('Availability:', data);
        setAvailability(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching availability:', error);
        setAvailability([]); // Ensure availability is always an array
      }
    };

    if (sid) {
      fetchOrders();
      fetchAvailability();
    }
  }, [sid]);

  const selectedDateOrders = Array.isArray(orders) ? orders.filter(
    order => new Date(order.Bdate).toDateString() === date.toDateString()
  ) : [];

  console.log(date.toDateString());

  const countOrdersForDate = (date) => {
    return Array.isArray(orders) ? orders.filter(order => new Date(order.Bdate).toDateString() === date.toDateString()).length : 0;
  };

  const handelOrderStatus = async (oid) => {
    console.log("order", oid);

    try {
      const response = await axios.patch(`https://homesalon.onrender.com/order/update-order-accept/${oid}`);
      console.log('res', response);
      if (response.status === 200) {
        alert("Order is Done");
        setOrders(prevOrders => 
          Array.isArray(prevOrders) ? prevOrders.map(order => order._id === oid ? { ...order, status: 'Done' } : order) : []
        );
      } else {
        alert("Failed to update address");
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const isAvailableDate = (date) => {
    return Array.isArray(availability) ? availability.some(avail => new Date(avail.date).toDateString() === date.toDateString()) : false;
  };

  const handleNavigate = (orderId, sid) => {
    if (sid && orderId) {
      navigate('/Rejected', { state: { sid, orderId } });
    } else {
      console.error('Invalid sid or orderId');
    }
  };

  return (
    <div className="mydiv">
      <div className="calendar-container">
        <Calendar
          onChange={setDate}
          value={date}
          tileContent={({ date, view }) =>
            view === 'month' && countOrdersForDate(date) > 0 ? (
              <div className="dots-container">
                {Array(countOrdersForDate(date)).fill(0).map((_, index) => (
                  <div key={index} className="dot"></div>
                ))}
              </div>
            ) : null
          }
          tileClassName={({ date, view }) => 
            view === 'month' && isAvailableDate(date) ? 'available-day' : null
          }
        />
        <div className="selected-date">
          <h2>Selected date: {date.toDateString()}</h2>
          <h3>Order Details:</h3>
          {loading ? (
            <p>Loading...</p>
          ) : selectedDateOrders.length > 0 ? (
            <ul>
              {selectedDateOrders.map((order, index) => (
                <li key={index} className="order-details">
                  <p><strong>Customer Name:</strong> {order.cname}</p>
                  <p><strong>Address:</strong> {order.Address}</p>
                  <div><strong>Service Name:</strong>
                    {order.services.map((service, index) => (
                      <p className="text-muted mb-0" key={index}>{service.name} - {service.cost}</p>
                    ))}
                  </div>
                  <p><strong>Total Charges:</strong> {order.Totalcost}</p>
                  <p><strong>Status:</strong> {order.status}</p>
                  <div className='btndiv'>
                    {order.status === 'Accept' || order.status === 'Pending' ? (
                      <>
                        <Button onClick={() => handleNavigate(order._id, order.sid)}>Reject Order</Button>
                        <Button onClick={() => handelOrderStatus(order._id)}>Done</Button>
                      </>
                    ) : order.status === 'rejected' ? (
                      <b>Order is Rejected</b>
                    ) : (
                      <b>Order Is Done Successfully</b>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No orders for this date.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyCalendar;
