import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import '../myaccoutnts/profiledetail.css';
import { Delete, Edit } from '@mui/icons-material';
import Loading from '../../../Loading';

const Address = () => {
  const [userRole, setUserRole] = useState({});
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setUserRole(resObject.user);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userRole.id) {
      fetchUserData(userRole.id);
    }
  }, [userRole]);

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`https://homesalon.onrender.com/address/users/${userId}/addresses`);
      setUserAddresses(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const uid= userRole.id
  const handleDelete = async (id) => {
    console.log(uid);
    try {
      await axios.delete(`https://homesalon.onrender.com/address//users/${uid}/address/${id}`);
      setUserAddresses(userAddresses.filter(address => address._id !== id));
    } catch (error) {
      console.error('Error deleting address:', error.message);
    }
  };

  const handleEdit = (addid) => {
    console.log('hii');
    navigate("/Addressupdate", {
      state: {
        selectedId: addid,
        // selectedAvailability: availability,
        // selectedDate: selectedDate, // Pass the selected date to the bill component
      },
    });
  };

  // const handleAddressSelect = (event) => {
  //   const addressId = event.target.value;
  //   const address = userAddresses.find(addr => addr._id === addressId);
  //   setSelectedAddress(address);
  // };

  // const handleContinueShopping = () => {
  //   if (!selectedAddress) {
  //     alert('Please select an address before proceeding.');
  //     return;
  //   }
  //   navigate('/Avaliblity', { state: { selectedAddress, cid: userRole.id } });
  // };

  return (
    <div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          {userAddresses.length === 0 ? (
            <div>
              <div>
                <Link to="/Addaddress">
                  <Button>Add New Address</Button>
                </Link>
              </div>
              <div>Please add an address.</div>
            </div>
          ) : (
            <>
              <div>
                <Link to="/Addaddress">
                  <Button>Add New Address</Button>
                </Link>
              </div>
              
              <div className='addressdiv2'>
                {userAddresses.map(address => (
                  <div className='addressbo' key={address._id}>
                    <div className='addressdiv3'>
                      <div className='showdetail'>
                      
                        <div className='label-container'>
                          <label className='address-label'>
                            <div className='addrsapn'>
                              <p className='addrsapnp'>{address.fname} {address.lname}</p>
                              <p className='addrsapnp'>{address.Al1} <span>{address.Al2}</span></p>
                              <p className='addrsapnp'>{address.landmark}</p>
                              <p className='addrsapnp'>{address.city} <span>{address.state}</span></p>
                              <p className='addrsapnp'>{address.zipcode}</p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="add1div">
                        <Button onClick={() => handleEdit(address._id)}><Edit /></Button>
                        <Button onClick={() => handleDelete(address._id)}><Delete /></Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className='adddivc'>
                <Button onClick={goBack}>
                  Cancel
                </Button>
                {/* <Button onClick={handleContinueShopping}>
                  Select Date & Time
                </Button> */}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Address;
