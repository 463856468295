import React, { useEffect } from 'react';
import './cart.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, setCartItems, incrementQuantity, decrementQuantity } from '../../Context/Action/actions';

const Cart = ({ onClose, userRole }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.cartItems);
  const navigate = useNavigate();

  const TAX_RATE = 0.08; // 8% tax rate
  const SHIPPING_COST = 2.99;

  useEffect(() => {
    const storedData = localStorage.getItem('cartData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const expirationDate = new Date(parsedData.expirationDate);
      const now = new Date();
      if (expirationDate > now) {
        dispatch(setCartItems(parsedData.cartItems));
      } else {
        localStorage.removeItem('cartData');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
    localStorage.setItem('cartData', JSON.stringify({ cartItems, expirationDate }));
  }, [cartItems]);

  const handleButtonClick = () => {
    if (userRole === 'customer') {
      onClose();
      navigate('/Address-Checkout');
    } else if (userRole && userRole.userRole === 'customer') {
      onClose();
      navigate('/Address-Checkout');
    } else {
      navigate('/login');
    }
  };

  const ButtonClick = () => {
    onClose();
    navigate('/Services');
  };

  const handleRemoveFromCart = (index) => {
    dispatch(removeFromCart(index));
  };

  const handleIncrement = (index) => {
    dispatch(incrementQuantity(index));
  };

  const handleDecrement = (index) => {
    dispatch(decrementQuantity(index));
  };

  const subtotal = cartItems.reduce((acc, item) => {
    const itemCost = item.service ? item.service.cost : item.cost;
    return acc + (parseFloat(itemCost || 0) * item.service.quantity);
  }, 0);

  const tax = subtotal * TAX_RATE;
  const totalWithTax = subtotal + tax;
  const grandTotal = totalWithTax + SHIPPING_COST;

  return (
    <div className='cart'>
      <h1>Your Cart</h1>
      
      {cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <div className="cardiv1" key={index}>
            <div className="cardiv2">
              <img src={item.service ? item.service.serviceImage : item.serviceImage} alt="" className="carimg" />
            </div>
            <div className="cardiv3">
              <h6>{item.service ? item.service.serviceName : item.name}</h6>
              <div className="quantity-controls">
                
                <Button onClick={() => handleDecrement(index)}>-</Button>
                <span style={{margin:'2px'}}>{item.service.quantity}</span>
                <Button onClick={() => handleIncrement(index)}>+</Button>
              </div>
              <div onClick={() => handleRemoveFromCart(index)}><Delete /></div>
            </div>
          </div>
        ))
      ) : (
        <div className="cart-empty">
          <p>Your cart is empty</p>
          <Button onClick={ButtonClick} style={{ marginLeft: "124px", marginRight: "124px" }}>Add Service</Button>
        </div>
      )}
      {cartItems.length > 0 && (
        <>
          <hr />
         
          <div className="Cardiv10">
            <div>
              <Button onClick={ButtonClick}>Continue Shopping</Button>
              <Button onClick={handleButtonClick}>Check Out</Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
