import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Addservice.css';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests

const AddSubservices = () => {
  const navigation = useNavigate();
  const [services, setServices] = useState([]); // Added state for services
  const [serviceData, setServiceData] = useState({
    serviceImage: null,
    serviceTypeName: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch(`${apiUrl}/auth/login/success`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // const resObject = await response.json();
          // setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // const decodedToken = jwtDecode(token);
          // setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();

    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service/servicetypes`);
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  // const handleChange = (e) => {
  //   const { id, value, files } = e.target;
  //   setServiceData((prevData) => ({
  //     ...prevData,
  //     [id]: files ? files[0] : value,
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   console.log("inside");
  //   e.preventDefault();
  //   const formData = new FormData();
  //   // formData.append('userId', userid);
  //   formData.append('serviceImage', serviceData.serviceImage);
  //   formData.append('serviceTypeName', serviceData.serviceTypeName);

  //   try {
  //     const response = await axios.post(`${apiUrl}/service/service-type`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     setServiceData({
  //       serviceImage: null,
  //       serviceTypeName: '',
  //       // Add other fields you want to clear here
  //     });
  //     console.log(response);
  //     alert('Service added successfully');
  //   } catch (error) {
  //     alert("Service Name Already Inserted");
  //     console.error('Error adding service:', error);
  //   }
  // };

  return (
    <div className="subadmindiv1">
      <div className="admindiv2">
        {/* <h1 style={{ textAlign: 'center' }}>Add Service</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="serviceImage">
            <Form.Label>Service Image</Form.Label>
            <Form.Control type="file" accept="image/*" onChange={handleChange} required />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="serviceTypeName">
              <Form.Label>Service Type Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Service Name" onChange={handleChange} required />
            </Form.Group>
          </Row>
          <Button style={{ width: "200px" }} variant="primary" type="submit">
            Submit
          </Button>
        </Form> */}

        <Button onClick={() => { navigation('/service-name') }}>Add New Service</Button>
        <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Service List</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Service Image</th>
              <th>Service Type</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service._id}>
                <td><img src={service.image} alt="Service" style={{ width: '100px' }} /></td>
                <td>{service.servicetype}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddSubservices;
