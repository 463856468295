
import avtar from '../../Componenet/image/img_avatar2.png'
import gogglelog from '../../Componenet/image/images.png'
import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { useAuth } from '../../../Auth/store';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';

const Login = ({checklogin}) => {
  const google = () => {
    window.open("https://homesalon.onrender.com/auth/google", "_self");
    console.log(google);
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
    const { storeTokenInLS }= useAuth();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false); // State to toggle showing password

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!formData.email || !formData.password) {
            alert("Please provide email and password");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/register/login`, formData);
          console.log(response);
            if (response.status === 200) {
                if (response.data.success) {
                    console.log(response.data.token);
                    
                    storeTokenInLS(response.data.token)
                    const token = localStorage.getItem('token');
                    if (token) {
                        const decodedToken = jwtDecode(token);
          
                        console.log(decodedToken);
                        
                        const userRole = decodedToken.role;  // Assuming the role is provided in the response data
                       console.log(userRole);
                        switch (userRole) {
                            case 'Service Provider':
                                navigate('/availability');
                                break;
                            case 'customer':
                                navigate('/services');
                                break;
                            case 'Admin':
                                navigate('/add-service');
                                break;
                            default:
                                alert("Unknown role: " + userRole);
                        }
                    }
                    // alert("Login Successful");
        
            
                    // navigate('/Services');
                    checklogin();
                } else {
                    alert("Login Failed: " + response.data.message);
                }
            }
        } catch (error) {
            console.error("Error:", error.data);
            alert("Failed to login. Please try again later.");
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState); // Toggle the state
    };

  return (
    <div className='login'>
   <div className='logindiv1'>
                <div className="imgcontainer">
                    <img src={avtar} alt="Avatar" className="avatar" />
                </div>
            <form onSubmit={handleSubmit}>

                <div className="containerlo">
                    <label htmlFor="email"><b>Email</b></label>
                    <input type="text" placeholder="Enter Email" name="email" required value={formData.email} onChange={handleChange} />

                    <label htmlFor="password"><b>Password</b></label>
                    <div className="input-field">
                        <input type={showPassword ? "text" : "password"} placeholder="Enter Password" name="password" required value={formData.password} onChange={handleChange} />
                        <button type="button" className="password-toggle" onClick={togglePasswordVisibility}>{showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</button>
                    </div>

                    <Button type="submit"  className="btmsu">Login</Button>
                    <label>
                        <input type="checkbox" defaultChecked name="remember" /> Remember me
                    </label>
                    <Button onClick={google} className="btmsu"><img src={gogglelog} alt="" srcset="" className='logog'/> Login with google</Button>
                </div>

                <div className="container" style={{ backgroundColor: '#f1f1f1' }}>
                    {/* <Button type="button"  className="btmsu" onClick={() => document.getElementById('id01').style.display = 'none'}>Cancel</Button> */}
                    <span className="psw"> Forgot <Link to="#">password? </Link></span>
                </div>
                    <span className="psw"> <Link to={'/register'}>Sign up as a new user</Link></span>
            </form>
        </div>
    
    
    </div>
  );
};

export default Login;
