import React from 'react'
import Homecau from './Homecau'

const SpHomeimg = () => {
  return (
    <div className='hdiv1'>
    <div className="hdiv2">
      <div className="hdiv3">
        <h1 className='hh1'>
        Salon at your door in these locations:
        </h1> 
        <p className="hp1">
        Los Angeles | Las Vegas | Connecticut | Boston | Westchester | Charleston | Orange County
        </p>

      </div>
      
    </div>
   <Homecau/>
</div>
  )
}

export default SpHomeimg
