import React, { useEffect, useState } from 'react';
import './profiledetail.css';
import { Link, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { jwtDecode } from 'jwt-decode';
import { Button } from 'react-bootstrap';


const Profiledetail = () => {
  const navigation =useNavigate()
  const [userdetail, setuserdetail] = useState(); // Corrected useState syntax
  const goBack = () => {
    navigation(-1); // Navigate back in the browser history
  };
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          // setIsLoggedIn(true);
          console.log(resObject);
          setuserdetail(resObject.user);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();
    const fetchUserInfo = async () => {
      try {
        //   checklogin();
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setuserdetail(decodedToken);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  console.log(userdetail);

  return (
    <div className='detmain'>
      <div className="detdiv1">
  
        <div className="adiv11" onClick={(()=>{goBack()})}>

<ArrowBackIcon/>
</div>
<h1>
Personal Info
        </h1>
      </div>
      <div className="detdiv2">
        <div className="detdiv3">
          <h6 className="deth6">Name</h6>
          <p className="detp">{userdetail && userdetail.displayName}</p>
        </div>
        <div className="detdiv4">
         
        </div>
      </div>
      <hr/>
      <div className="detdiv2">
        <div className="detdiv3">
          <h6 className="deth6">Email</h6>
          <p className="detp">{userdetail && userdetail.email}</p>
        </div>
        <div className="detdiv4">
          
        </div>
      </div>
      <hr/>
      <div className="detdiv2">
        <div className="detdiv3">
          <h6 className="deth6">Phone Number</h6>
          <p className="detp">{userdetail && userdetail.phone}</p>
        </div>
        <div className="detdiv4">
          <Button onClick={(()=>{navigation('/user-update')})}>Edit</Button>
        </div>
      </div>
    </div>
  );
}

export default Profiledetail;
