import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { addToCart, removeFromCart } from '../../Context/Action/actions';
import Cartcop from './Cartcop';
import { Close } from '@mui/icons-material';
import './oservice.css'
import '../../Componenet/Service/services.css'
import axios from 'axios';
import Loading from '../../../Loading.jsx';

const Oservice = (userRole) => {
    const [services, setServices] = useState([]);
    const [servicet, setServicet] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cartItems);
    const [selectedService, setSelectedService] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(apiUrl);
    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service/servicetypes`);
                setServices(response.data);
                setIsLoading(false);
                if (response.data.length > 0) {
                    fetchServices(response.data[0].servicetype); // Fetch sub-services for the first service type
                }
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };
        fetchServiceTypes();
    }, []);

    const fetchServices = async (serviceType) => {
        try {
            const response = await axios.get(`${apiUrl}/service/services/by-type/${serviceType}`);
            setServicet(response.data.services);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const handleAddToCart = (service) => {
        dispatch(addToCart(service));
        setIsDrawerOpen(true);
    };

    const handleRemoveFromCart = (index) => {
        dispatch(removeFromCart(index));
    };

    const handleViewDetails = (service) => {
        setSelectedService(service);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setShowDetails(false);
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    if (isLoading) {
        return <Loading />; // Show loading indicator while data is being fetched
    }
    console.log(services);

    return (
        <div className="omain">
            <div className="arrow-buttonl" onClick={scrollLeft}>{'<'}</div>
            <div className="ocdiv10" ref={containerRef}>
                <div style={{ display: 'flex', scrollbarWidth: 'none' }}>
                    {services.map((service, index) => (
                        <div className="imgdiv" key={index} onClick={() => fetchServices(service.servicetype)}>
                            <img src={`${apiUrl}/${service.image}`} alt={service.servicetype} className="ocimg" />
                            <h6>{service.servicetype}</h6>
                        </div>
                    ))}
                </div>
            </div>
            <div className="arrow-button" onClick={scrollRight}>{'>'}</div>
            <div className='ocdiv'>
                {servicet && servicet.map((service, index) => (
                    <div className="ocdiv1" key={index}>
                        <div className="ocdiv2">
                            <img src={service.serviceImage} alt={service.serviceName} className="cimg" />
                        </div>
                        <div className="ocdiv3">
                            <h6>{service.serviceName}</h6>
                            <Link to="#" onClick={() => handleViewDetails(service)}>View Details</Link>
                            <div className="ocdiv4">
                                <div className="oouter-container">
                                    <div className="omain-content">
                                        <Button onClick={() => handleAddToCart({ service })}>Add To Cart</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <Cartcop
                    isOpen={isDrawerOpen}
                    onClose={toggleDrawer}
                    cartItems={cartItems}
                    userRole={userRole}
                    removeFromCart={handleRemoveFromCart}
                />
                <Dialog open={showDetails} onClose={handleCloseDetails}>
                    <DialogActions sx={{ justifyContent: 'space-between' }}>
                        <DialogTitle>{selectedService?.serviceName}</DialogTitle>
                        <Button onClick={handleCloseDetails} className='Btncl'><Close /></Button>
                    </DialogActions>
                    <DialogContent>
                        <h5>DESCRIPTION:</h5>
                        <div>
                            {selectedService?.description}
                        </div>
                        <h5>SAFETY:</h5>
                        <div>
                            {selectedService?.safety}
                        </div>
                        <h5>THINGS TO KNOW:</h5>
                        <div>
                            {selectedService?.thingsToKnow}
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

export default Oservice;
