import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AvailabilityCalendar.css';
import HorizontalCalendar from './HorizontalCalendar ';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../../../Loading';

const Loadingpage = () => {
  const [serviceProviders, setServiceProviders] = useState([]);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [allProviderSlots, setAllProviderSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [zipcode, setZipcode] = useState('');
  const [serviceNames, setServiceNames] = useState([]);
  const [serviceqty, setserviceqty] = useState([]);
  const [loading, setLoading] = useState(false); // New state for loader
  const [showText, setShowText] = useState(false); // State for showing text on hover
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedAddress, cid } = location.state || {};
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const extractedZipcode = selectedAddress?.zipcode || '';
    setZipcode(extractedZipcode);

    const serviceNamesList = cartItems.map((item) => item.service.serviceName);
    setServiceNames(serviceNamesList);
    const qty =  cartItems.map((item) => item.service.quantity);
    console.log(qty,"qty");
    setserviceqty(qty);
    console.log(serviceqty);

    const fetchServiceProviders = async () => {
      try {
        console.log(serviceNamesList);
        setLoading(true); // Show loader
        if (serviceNamesList.length > 0 && extractedZipcode) {
          const formattedDate = dayjs(selectedDate).format('MM/DD/YYYY');
          const day = dayjs(selectedDate).format('dddd');
          const encodedServiceNames = serviceNamesList.map(name => encodeURIComponent(name)).join(',');
          const response = await axios.get(`${apiUrl}/service/fetch-slots-new?servicename=${encodedServiceNames}&zipcode=${extractedZipcode}&date=${formattedDate}&day=${day}`);
          if (response.data.users) {
            console.log(response.data.users);
            setServiceProviders(response.data.users);
          } else {
            setServiceProviders([]);
          }
        }
      } catch (error) {
        console.error('Error fetching service providers:', error);
        setServiceProviders([]);
      } finally {
        setLoading(false); // Hide loader
      }
    };
    fetchServiceProviders();
  }, [selectedAddress, cartItems]);

  const fetchAvailableSlots = async (providerId, day, formattedDate) => {
    try {
      console.log(serviceNames);
      setLoading(true); // Show loader
      const encodedServiceNames = serviceNames.map(name => encodeURIComponent(name)).join(',');
      const response = await axios.get(`${apiUrl}/service/fetch-slots-user?servicename=${encodedServiceNames}&zipcode=${zipcode}&day=${day}&date=${formattedDate}&userId=${providerId}`);
      if (response.data.users) {
        console.log(response.data.users);
        setAvailableSlots(response.data.users);
      } else {
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setAvailableSlots([]);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const fetchAllProvidersSlots = async (day, formattedDate) => {
    try {
      setLoading(true); // Show loader
      console.log(serviceNames);
      const encodedServiceNames = serviceNames.map(name => encodeURIComponent(name)).join(',');
      const response = await axios.get(`${apiUrl}/service/fetch-slots-new?servicename=${encodedServiceNames}&zipcode=${zipcode}&day=${day}&date=${formattedDate}`);
      if (response.data.users) {
        console.log(response.data.users);
        setAllProviderSlots(response.data.users);
      } else {
        setAllProviderSlots([]);
      }
    } catch (error) {
      console.error('Error fetching all providers slots:', error);
      setAllProviderSlots([]);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleDateSelect = (date) => {
    if (!selectedDate.isSame(date, 'day')) {
      setSelectedDate(date);
      const day = dayjs(date).format('dddd');
      const formattedDate = dayjs(date).format('MM/DD/YYYY');
      if (selectedServiceProvider) {
        fetchAvailableSlots(selectedServiceProvider, day, formattedDate);
      } else {
        fetchAllProvidersSlots(day, formattedDate);
      }
    }
  };

  const handleServiceProviderChange = (e) => {
    const providerId = e.target.value;
    setSelectedServiceProvider(providerId);
    const day = dayjs(selectedDate).format('dddd');
    const formattedDate = dayjs(selectedDate).format('MM/DD/YYYY');
    if (providerId) {
      fetchAvailableSlots(providerId, day, formattedDate);
    } else {
      fetchAllProvidersSlots(day, formattedDate);
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate back in the browser history
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  // console.log(provider.paymentMethods);

  const handleButtonClick = (provider, slotIndex) => {
    console.log("slot", provider);
    
    navigate('/bill', {
      state: {
        selectedServiceProvider: provider.spid,
        selectedProviderDisplayName: provider.spName,
        selectedAvailability: provider.slots[slotIndex],
        selectedDate: selectedDate,
        selectedAddress: selectedAddress,
        cid: cid,
        services: provider.services, // Pass the services array
        paymentMethods: provider.paymentmethod, // Pass the payment methods array
        quantities: serviceqty, // Pass the quantities
      },
    });
  };

  const handleOpenButtonClick = () => {
    navigate('/Addrequest', {
      state: {
        selectedAddress: selectedAddress,
        cid: cid,
      },
    });
  };
console.log(serviceqty);
  const calculateTotalCost = (services) => {
    return services.reduce((total, service, index) => {
      const quantity = serviceqty[index] || 1; // Default to 1 if quantity is undefined
      return total + service.cost * quantity;
    }, 0);
  };

  // console.log(provider);

  return (
    <div className="availability-calendar">
      <h1>Select Date & Time</h1>
      <div className="headersav">
        <div className="lodiv">
          <select value={selectedServiceProvider} onChange={handleServiceProviderChange}>
            <option value="">All Service Providers</option>
            {serviceProviders.map((provider) => (
              <option key={provider.spid} value={provider.spid}>
                {provider.spName} - ${calculateTotalCost(provider.services)} {/* Display total cost */}
              </option>
            ))}
          </select>

          <DatePicker
            selected={selectedDate.toDate()}
            onChange={(date) => handleDateSelect(dayjs(date))}
            customInput={<ExampleCustomInput />}
          />
        </div>
        <HorizontalCalendar selectedDate={selectedDate} onDateSelect={handleDateSelect} />
      </div>
      {loading ? (
        <div style={{ marginTop: '150px' }}>
          <Loading />
        </div>
      ) : (
        <div className="slots-container">
          {selectedServiceProvider ? (
            availableSlots.length > 0 && availableSlots[0].slots.length ? (
              availableSlots.map((providerSlots, index) => (
                <div key={index}>
                  {providerSlots.slots.length > 0 && 
                    <h3>
                      {providerSlots.spName} - ${calculateTotalCost(providerSlots.services)}
                    </h3>
                  }
                  <div className="time-slots">
                    {providerSlots.slots.map((slot, slotIndex) => (
                      <button
                        key={slotIndex}
                        className="time-slot"
                        onClick={() => handleButtonClick(providerSlots, slotIndex)}
                      >
                        {slot.startTime}
                      </button>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div>No Service Provider Available</div>
            )
          ) : allProviderSlots.length > 0 && allProviderSlots[0].slots.length ? (
            allProviderSlots.map((providerSlots, index) => (
              <div key={index}>
                {providerSlots.slots.length > 0 && (
                  <div
                  style={{marginBottom:'10px'}}
                    onMouseEnter={() => setShowText(true)}
                    onMouseLeave={() => setShowText(false)}
                  >
                    <span>
                      <b>Service provider name: </b>{providerSlots.spName}
                    </span>
                    <br />
                    <span>
                      <b>Total cost: </b>${calculateTotalCost(providerSlots.services)}
                    </span>
                    {showText && (
                      <div>
                        {providerSlots.services.map((service, serviceIndex) => (
                          <div key={serviceIndex}>
                            <b>{service.serviceName}</b>: ${service.cost}
                           
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div className="time-slots">
                  {providerSlots.slots.map((slot, slotIndex) => (
                    <button
                      key={slotIndex}
                      className="time-slot"
                      onClick={() => handleButtonClick(providerSlots, slotIndex)}
                    >
                      {slot.startTime}
                    </button>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div>No Service Provider Available</div>
          )}
        </div>
      )}
      {!loading && (
        <div>
          <Button onClick={goBack}>Cancel</Button>
        </div>
      )}
    </div>
  );
};

export default Loadingpage;
