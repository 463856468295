import React, { useState, useEffect } from 'react';
import './CustomerFeedback.css';
import { Button, Spinner, Nav } from 'react-bootstrap';

const CustomerFeedback = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('pending');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://homesalon.onrender.com/reviews/reviews');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setCustomers(data);
                setLoading(false); // Set loading to false after data fetch
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false); // Set loading to false on error
            }
        };

        fetchData();
    }, []);

    const showAll = async (reviewId) => {
        try {
            const response = await fetch(`https://homesalon.onrender.com/reviews/reviews/${reviewId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ show: true }),
            });

            if (!response.ok) {
                throw new Error('Failed to update review.');
            } else {
                alert('Approved Successfully');
            }

            const updatedCustomers = customers.map(customer => {
                if (customer._id === reviewId) {
                    return { ...customer, show: true };
                }
                return customer;
            });

            setCustomers(updatedCustomers);
        } catch (error) {
            console.error('Error updating review:', error);
        }
    };

    const isOlderThanSevenDays = (createdAt) => {
        const reviewDate = new Date(createdAt);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - reviewDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        return diffDays > 7;
    };

    const filterReviews = (reviews, status) => {
        if (status === 'pending') {
            return reviews.filter(review => !review.show);
        } else if (status === 'approved') {
            return reviews.filter(review => review.show);
        } else if (status === 'archived') {
            return reviews.filter(review => !review.show && isOlderThanSevenDays(review.createdAt));
        }
    };

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
    }

    const filteredCustomers = filterReviews(customers, activeTab);

    return (
        <div className="customer-feedback-container">
            <h1>Customer Feedback</h1>
            <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="pending" className={activeTab === 'pending' ? 'active-tab' : ''}>Pending</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="approved" className={activeTab === 'approved' ? 'active-tab' : ''}>Approved</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="archived" className={activeTab === 'archived' ? 'active-tab' : ''}>Archived</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="custcard2">

            {filteredCustomers.map(customer => (
                <div key={customer.id} className="customer-card">
                    <p><b>Customer Name : </b>{customer.userId.displayName}</p>
                    <p><b>Provider Name : </b>{customer.sid.displayName}</p>
                    <p>{customer.feedback}</p>
                    <div className="stars">
                        {Array.from({ length: customer.rating }).map((_, index) => (
                            <span key={index}>&#9733;</span>
                        ))}
                    </div>
                    <Button 
                        onClick={() => showAll(customer._id)} 
                        disabled={isOlderThanSevenDays(customer.createdAt) || customer.show}
                    >
                        Add In Home Page
                    </Button>
                </div>
            ))}
            </div>
        </div>
    );
};

export default CustomerFeedback;
