import React from 'react'

const Contactus = () => {
  return (
    <div className='adiv1'>
        <h1 className='abh1'>CONTACT US</h1>
        <div className="codiv1">
            <div className="codiv">
                <div>
                    Phone:<span>(206) 123-4567</span>
                </div>
                <div>
                    Email:<span>illias.ralf@foodfarms.net</span>
                </div>
               <div>    
                  Address:<span>129 3710 Landon Lodge, South Nathaniel, ME 36719-5660</span>
               </div>
              
            </div>
        </div>
    </div>
  )
}

export default Contactus