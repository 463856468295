
import avtar from '../../Componenet/image/img_avatar2.png'
import gogglelog from '../../Componenet/image/images.png'
import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { Link } from "react-router-dom";
// import { useAuth } from '../../../store/Auth';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from 'react-bootstrap';
const Register = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const google = () => {
        window.open("https://homesalon.onrender.com/auth/google", "_self");
      };
    //   const navigate = useNavigate();
      // const { storeTokenInLS }= useAuth();
      const [formData, setFormData] = useState({
        displayName:'',
          email: '',
          password: '',
          otp:''

      });
      const [showPassword, setShowPassword] = useState(false); // State to toggle showing password
    
      const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/register/signup`, {
                displayName: formData.displayName, // Map firstName to displayName for consistency with backend
                email: formData.email,
                password: formData.password,
                Phone: formData.Phone
            });
            console.log(response);
            if (response.status === 200) {
                // alert("OTP sent to your email. Please check your inbox and enter the OTP.");
                const otp = prompt("Please enter the OTP sent to your email");
                if (otp) {
                    setFormData(prevState => ({
                        ...prevState,
                        otp: otp
                    }));
                    handleOTPVerification();
                } else {
                    alert("OTP verification canceled.");
                }
            }
            
        }
         catch (error) {
            if (error.response.status === 400) {
                alert("User already exists. Please login.");
            } else {
                console.error("Error:", error.response.data);
                alert("Failed to signup. Please try again later.");
            }
        }
    };

    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const togglePasswordVisibility = () => {
          setShowPassword(prevState => !prevState); // Toggle the state
      };


      const handleOTPVerification = async () => {
        try {
            const response = await axios.post(`${apiUrl}/register/verify-otp`, {
                
                otp: formData.otp,
                displayName: formData.displayName, // Map firstName to displayName for consistency with backend
                email: formData.email,
                password: formData.password,
                Phone: formData.Phone
            });
            console.log(response);
            if (response.status === 201) {
                alert("User registered successfully");
                // Optionally, redirect the user to another page after successful registration
            }
        } catch (error) {
            console.error("Error:", error.response.data);
            alert("Failed to register user. Please try again later.");
        }
    };
  return (
    <div className='login'>
   <div className='logindiv1'>
                <div className="imgcontainer">
                    <img src={avtar} alt="Avatar" className="avatar" />
                </div>
            <form onSubmit={handleSubmit}>

                <div className="containerlo">
                    <label htmlFor="displayName"><b>Name</b></label>
                    <input type="text" placeholder="Enter Name" name="displayName" required value={formData.displayName} onChange={handleChange} />
                    <label htmlFor="email"><b>Email</b></label>
                    <input type="text" placeholder="Enter Email" name="email" required value={formData.email} onChange={handleChange} />

                    <label htmlFor="password"><b>Password</b></label>
            <div className="input-field">
                        <input type={showPassword ? "text" : "password"} placeholder="Enter Password" name="password" id="password" value={formData.password} onChange={handleChange} required  />
                        <button type="button" className="password-toggle" onClick={togglePasswordVisibility}>{showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</button>
                    </div>

            <label htmlFor="repeatPassword"><b>Confirm Password</b></label>
            <div className="input-field">
                        <input type={showPassword ? "text" : "password"} placeholder="Confirm Password" name="repeatPassword" id="repeatPassword" value={formData.repeatPassword} onChange={handleChange} required />
                        <button type="button" className="password-toggle" onClick={togglePasswordVisibility}>{showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</button>
                    </div>


                    <Button className="btmsu" type="submit">Sign up</Button>
                    <label>
                        <input type="checkbox" defaultChecked name="remember" /> Remember me
                    </label>
                    <Button onClick={google} className="btmsu" ><img src={gogglelog} alt="" srcset="" className='logog'/> Login with google</Button>
                </div>

                <div className="container" style={{ backgroundColor: '#f1f1f1' }}>
                    <Button type="button" onClick={() => document.getElementById('id01').style.display = 'none'} className="cancelbtn">Cancel</Button>
                    <span className="psw">Forgot <Link to="#">password?</Link></span>
                </div>
                    <span className="psw"> <Link to={"/login"}>Sign in as a new user</Link></span>
            </form>
        </div>
    
    
    </div>
  )
}

export default Register
