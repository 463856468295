import React from 'react'
// import Homimg from '../Componenet/Home/Homimg'
import Homeaboutus from '../Componenet/Home/Homeaboutus'
import './homep.css'
import Contactus from '../Componenet/Home/Contactus'
import Testminal from '../Componenet/Home/Testminal'
import SpHomeimg from '../Componenet/Home/SpHomeimg'


const HomeSp = () => {
  return (
    <div className='hdiv'>
    <div className='Home'>

      <SpHomeimg/> 
   
    </div>
      <Homeaboutus/>
      <Contactus/>
      <Testminal/>
  </div>
  )
}

export default HomeSp
