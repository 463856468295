import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import './profiledetail.css'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
// import { Delete } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { Select } from '@mui/material';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
const AddAddress = () => {
    const [userRole, setUserRole] = useState('');
  // const [useraddresses, setuseraddresses] = useState('');
  const navigation=useNavigate();

  const goBack = () => {
    navigation(-1); // Navigate back in the browser history
  };
  const [formData, setFormData] = useState({
    fname:'',
    lname:'',
    Al1:'',
    Al2:'',
    landmark:'',
    city: '',
    state: '',
    zipcode: ''

  });
  const [cities, setCities] = useState([]); // State to store cities
  const [states, setStates] = useState([]); // State to store states
  const [Zipcodes, setZipcodes] = useState([]); // State to store zipcodes
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedId } = location.state || {}; // Destructure address from location state
console.log(selectedId);
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setUserRole(resObject.user);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('https://homesalon.onrender.com/Admin/address/cities');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(`https://homesalon.onrender.com/address/${selectedId}`);
        const addressData = response.data;
        console.log(addressData);
        setFormData({
          fname: addressData.fname || '',
          lname: addressData.lname || '',
          Al1: addressData.Al1 || '',
          Al2: addressData.Al2 || '',
          landmark: addressData.landmark || '',
          city: addressData.city || '',
          state: addressData.state || '',
          zipcode: addressData.zipcode || ''
        });
        setStates([addressData.state]); // Assuming states are fetched based on the address
        setZipcodes([addressData.zipcode]); // Assuming zipcodes are fetched based on the address
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    };

    if (selectedId) {
      fetchAddress();
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      setFormData({
        fname: selectedId.fname || '',
        lname: selectedId.lname || '',
        Al1: selectedId.Al1 || '',
        Al2: selectedId.Al2 || '',
        landmark: selectedId.landmark || '',
        city: selectedId.city || '',
        state: selectedId.state || '',
        zipcode: selectedId.zipcode || ''
      });
    }
  }, [selectedId]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'city') {
      try {
        const encodedCity = encodeURIComponent(value);
        const response = await axios.get(`https://homesalon.onrender.com/Admin/address/address/${encodedCity}`);
        setStates(response.data.states);
        setZipcodes(response.data.zipCodes);
      } catch (error) {
        console.error('Error fetching states and zipcodes:', error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = userRole.id;
    console.log(formData);
    if (!formData.fname || !formData.lname || !formData.Al1 || !formData.Al2 || !formData.city || !formData.state || !formData.zipcode) {
      alert("Please provide all required fields");
      return;
    }

    try {
      let response;
      if (selectedId) {
        console.log('hii',selectedId);
        // Update existing address
        response = await axios.patch(`https://homesalon.onrender.com/address/${selectedId}`, formData);
        console.log('res',response);
        if (response.status === 200) {
          alert("Address updated successfully");
        } else {
          alert("Failed to update address");
        }
      } else {
        // Add new address
        console.log('hii',uid);
        response = await axios.post(`https://homesalon.onrender.com/address/${uid}`, formData);
        if (response.status === 201 && response.data.success) {
          alert("Address added successfully");
        } else {
          alert(response.data.message || "Failed to add address");
        }
      }
      navigate('/Address');
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to add/update address. Please try again later.");
    }
  };

  return (
    <div className='address'>
         <div className='detmain1'>
    
       <div className="detdiv1">
         <h1>Add Address</h1>
       </div>

       

       <Form onSubmit={handleSubmit}>

       <Row className="mb-3">
        
         {/* <div className="detdiv2"> */}

            <Form.Group as={Col} controlId="formGridEmail">
            <div className="detdiv3">
            <Form.Label className="deth6">First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter First Name" name="fname" required value={formData.fname} onChange={handleChange} />
            </div>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
            <div className="detdiv3">

            <Form.Label className="deth6">Last Name</Form.Label>
              <input type="text" placeholder="Enter Last Name" name="lname" required value={formData.lname} onChange={handleChange} />
            </div>
          </Form.Group>
          {/* </div> */}
          </Row>
        
         
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <div className="detdiv3">

            <Form.Label className="deth6">Street Number And Street Name</Form.Label>
              <Form.Control type="text" placeholder="Street Number And Street Name"   name="Al1" required value={formData.Al1} onChange={handleChange} />
            </div>
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formGridAddress1">

            <div className="detdiv3">

            <Form.Label className="deth6">Apartment or unit and its number </Form.Label>
              <Form.Control className='addin' type="text" placeholder="Apartment or unit and its number" name="Al2" required value={formData.Al2} onChange={handleChange} />
            </div>

            </Form.Group>
         {/* <div className="detdiv2">

            <div className="detdiv3">

            <h6 className="deth6">Landmark</h6>
              <input type="text" placeholder="Enter Landmark" name="landmark" required value={formData.landmark} onChange={handleChange} />
            </div>

          </div> */}
           
           <Row className="mb-2">
         
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="deth6">City</Form.Label>
              <Form.Select   className='custom-select' name="city" value={formData.city} onChange={handleChange} required>
                <option value="">Select City</option>
                {cities.map(city => (
                  <option key={city.id} value={city}>{city}</option>
                ))}
              </Form.Select>   
              </Form.Group>


              <Form.Group as={Col} controlId="formGridState">

              <Form.Label className="deth6">State</Form.Label>
              <Form.Select   className='custom-select' name="state" value={formData.state} onChange={handleChange} required>
                <option value="">Select State</option>
                {states.map(state => (
                  <option key={state.id} value={state}>{state}</option>
                ))}
              </Form.Select>        
      </Form.Group>
       
        </Row>
       
          <Form.Group as={Col} controlId="formGridState">
  <div className="detdiv3">
    <Form.Label className="deth6">Zipcode</Form.Label>
    <Form.Select name="zipcode" className='custom-select' value={formData.zipcode} onChange={handleChange} required>
                <option value="">Select State</option>
                {Zipcodes.map(zip => (
                  <option key={zip.id} value={zip}>{zip}</option>
                ))}
              </Form.Select>   
  </div>
</Form.Group>
              <div className="addivc">

          <Button className="btmsu1"  onClick={(()=>{goBack()})}>Cancel</Button>
          <Button className="btmsu1" type="submit">Add Address</Button>
              </div>
        </Form>
        </div>

    </div>
  )
}

export default AddAddress