import React, { useState } from 'react';

const Adminedit = () => {
    // State variables to store content and background image
    const [aboutUsContent, setAboutUsContent] = useState('');
    const [homePageContent, setHomePageContent] = useState('');
    const [homePageBackground, setHomePageBackground] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    // Function to handle form submission for About Us content
    const handleAboutUsSubmit = (event) => {
        event.preventDefault();
        // Send API request to update About Us content
    };

    // Function to handle form submission for Home page content
    const handleHomePageSubmit = (event) => {
        event.preventDefault();
        // Send API request to update Home page content
    };

    // Function to handle form submission for Home page background
    const handleBackgroundSubmit = (event) => {
        event.preventDefault();
        // Send API request to update Home page background
    };

    return (
        <div style={{marginTop:'150px'}}>
            <h2>About Us</h2>
            <form onSubmit={handleAboutUsSubmit}>
                <textarea value={aboutUsContent} onChange={(e) => setAboutUsContent(e.target.value)} />
                <button type="submit">Save</button>
            </form>
            <h2>Home Page</h2>
            <form onSubmit={handleHomePageSubmit}>
                <textarea value={homePageContent} onChange={(e) => setHomePageContent(e.target.value)} />
                <button type="submit">Save</button>
            </form>
            <h2>Home Page Background</h2>
            <form onSubmit={handleBackgroundSubmit}>
                <input type="file" accept="image/*" onChange={(e) => setHomePageBackground(e.target.files[0])} value={homePageBackground}/>
                <button type="submit">Save</button>
            </form>
        </div>
    );
}

export default Adminedit
