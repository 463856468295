import React from 'react'
import './css/Home.css'
const images = [
    './assets/Best-Beauty-Salon-Services-in-Bundall-on-Gold-Coast.png',
    './assets/istockphoto-692999494-612x612.jpg',
    './assets/lovepik-barber-gives-the-customer-a-haircut-picture_501431865.jpg',
    './assets/photo-1570172619644-dfd03ed5d881.avif',
    './assets/photo-1580618672591-eb180b1a973f.avif',
    './assets/Best-Beauty-Salon-Services-in-Bundall-on-Gold-Coast.png',
    './assets/istockphoto-692999494-612x612.jpg',
    './assets/lovepik-barber-gives-the-customer-a-haircut-picture_501431865.jpg',
    './assets/photo-1570172619644-dfd03ed5d881.avif',
    './assets/photo-1580618672591-eb180b1a973f.avif',
    './assets/Best-Beauty-Salon-Services-in-Bundall-on-Gold-Coast.png',
    './assets/istockphoto-692999494-612x612.jpg',
    './assets/lovepik-barber-gives-the-customer-a-haircut-picture_501431865.jpg',
    './assets/photo-1570172619644-dfd03ed5d881.avif',
    './assets/photo-1580618672591-eb180b1a973f.avif',
   
    // Add more image paths as needed
  ];
const Homecau = () => {
    // const [currentIndex, setCurrentIndex] = useState(0);

  
  return (
    <div className='cau'>
          <div className="carousel1">
      {images.map((image, index) => (
        <div
        className='cudiv'
          key={index}
         
        >
          {<img src={image} alt={`Slide ${index}`} className='cuimg' />}
        </div>
      ))}
    </div>
    </div>
  )
}

export default Homecau