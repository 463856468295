import React from 'react'
import Profile from './Profile'
import './myaccount.css'

const Myaccount = () => {
  return (
    <div>
        <div className="mdiv1">
            <Profile/>
        </div>
    </div>
  )
}

export default Myaccount
