import React from 'react';
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './Customer/Context/store';

const rootElement = document.getElementById("root");

const renderApp = (AppComponent) => {
  const root = rootElement.hasChildNodes() ? hydrate : render;
  root(
    <React.StrictMode>
      <Provider store={store}>
        <AppComponent />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
};

renderApp(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
