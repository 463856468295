import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import './Addservice.css';
// import {jwtDecode} from 'jwt-decode'; // Corrected the import statement
import axios from 'axios'; // Import axios for making HTTP requests
import { useNavigate } from 'react-router-dom';

const Addsubservice = () => {
    const navigation = useNavigate();
  // const [userid, setUserid] = useState('');
  const [serviceData, setServiceData] = useState({
    serviceImage: null,
    serviceName: '',
    serviceType: '',
    // cost: '',
    // time: '',
    description: '',
    safety: '',
    thingsToKnow: '',
    Share: '',
  });
  const [serviceTypes, setServiceTypes] = useState([]); // State for service types
  const [services, setServices] = useState([]); // State for storing fetched services

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://homesalon.onrender.com/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // const resObject = await response.json();
          // setUserid(resObject.user.id);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUser();

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // const decodedToken = jwtDecode(token);
          // setUserid(decodedToken.id);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();

    const fetchServiceTypes = async () => {
      try {
        const response = await axios.get('https://homesalon.onrender.com/service/servicetypes');
        setServiceTypes(response.data);
      } catch (error) {
        console.error('Error fetching service types:', error);
      }
    };

    fetchServiceTypes();

    const fetchServices = async () => {
      try {
        const response = await axios.get('https://homesalonebackend-new.onrender.com/service/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setServiceData((prevData) => ({
      ...prevData,
      [id]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("inside");
    e.preventDefault();
    const formData = new FormData();
    formData.append('serviceImage', serviceData.serviceImage);
    formData.append('serviceName', serviceData.serviceName);
    formData.append('serviceType', serviceData.serviceType);
    // formData.append('cost', serviceData.cost);
    // formData.append('time', serviceData.time);
    formData.append('description', serviceData.description);
    formData.append('safety', serviceData.safety);
    formData.append('thingsToKnow', serviceData.thingsToKnow);
    formData.append('Share', serviceData.Share);

    try {
      const response = await axios.post(`https://homesalonebackend-new.onrender.com/service/mainservice`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      alert('Service added successfully');
      setServiceData({
        serviceImage: null,
        serviceName: '',
        serviceType: '',
        description: '',
        safety: '',
        thingsToKnow: '',
        Share: '',
      });
      navigation('/subservice')
    } catch (error) {
      alert("Service Name Already Inserted")
      console.error('Error adding service:', error);
    }
  };
  return (
    <div className="admindiv1">
      <div className="admindiv2">
           <h1 style={{ textAlign: 'center' }}>Add Sub Service</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="serviceImage">
            <Form.Label>Service Image</Form.Label>
            <Form.Control type="file" accept="image/*" onChange={handleChange} required />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="serviceType">
              <Form.Label>Service Type</Form.Label>
              <Form.Control as="select" onChange={handleChange} required>
                <option value="">Select Service Type</option>
                {serviceTypes.map((type) => (
                  <option key={type._id} value={type.servicetype}>
                    {type.servicetype}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="serviceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control placeholder="Enter Service Name" onChange={handleChange} required />
            </Form.Group>
          </Row>
          {/* <Row className="mb-3">
            <Form.Group as={Col} controlId="cost">
              <Form.Label>Cost</Form.Label>
              <Form.Control placeholder="Enter Cost" onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="time">
              <Form.Label>Time</Form.Label>
              <Form.Control placeholder="Require Time for service" onChange={handleChange} />
            </Form.Group>
          </Row> */}
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control placeholder="Brief Description About Service" as="textarea" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="safety">
            <Form.Label>Safety</Form.Label>
            <Form.Control placeholder="Which Safety get provide" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="thingsToKnow">
            <Form.Label>Things To Know</Form.Label>
            <Form.Control placeholder="Things To Know" as="textarea" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Share">
            <Form.Label>Share In Percentage</Form.Label>
            <Form.Control placeholder="Share In Percentage" onChange={handleChange} required />
          </Form.Group>
          <div className="frmdiv1">
            <Button style={{ width: "200px" }} variant="primary" type="submit">
              Submit
            </Button>
            <Button style={{ width: "200px" }} variant="primary" type="button">
              Cancel
            </Button>
          </div>
        </Form>
        </div>
    </div>
  )
}

export default Addsubservice