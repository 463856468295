import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

const Customerdetails = () => {
    const [serviceProviders, setserviceProviders] = useState([]);

    useEffect(() => {
        const fetchsp = async () => {
          try {
            const response = await axios.get('https://homesalon.onrender.com/register/serviceproviders/customer');
            console.log(response.data);
            setserviceProviders(response.data);
            // setCities(response.data);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchsp();
      }, []);
      console.log(serviceProviders);
      if (!serviceProviders.length) {
        return <div>Loading...</div>;
      }
    
  return (
    <div style={{marginTop:"150px"}}>
         <div>
      <h1>Customer Details</h1>
      <Table bordered hover responsive >
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            {/* <th>Address</th> */}
            <th>Address</th>
      
          </tr>
        </thead>
        <tbody>
          {serviceProviders.map(serviceProvider => (
            <tr key={serviceProvider._id}>
              <td>{serviceProvider.displayName}</td>
              <td>{serviceProvider.email}</td>
              <td>{serviceProvider.role}</td>
              {/* <td>{serviceProvider.addresses ? serviceProvider.addresses.join(', ') : 'No addresses available'}</td> */}
              <td>
              <ul>
                  {serviceProvider.addresses.map((address, index) => (
                    <li key={index}>
                      <div>{address.fname} {address.lname}</div>
                      <div>{address.Al1}, {address.Al2}</div>
                      <div>{address.landmark}</div>
                      <div>{address.city}, {address.state} - {address.zipcode}</div>
                    </li>
                  ))}
                </ul>
              </td>
              
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    </div>
  )
}

export default Customerdetails
