import React, { useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import {  Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './logo.png';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './Header.css';
// import { Button } from 'react-bootstrap';
import { Twitter } from '@mui/icons-material';
import Cartcop from '../Customer/Pages/Service/Cartcop';
import InstagramIcon from '@mui/icons-material/Instagram';
import {   setCartItems } from '../Customer/Context/Action/actions'; // import your actions
import { useSelector } from 'react-redux';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const Header = ({ user , checklogin }) => {
  // const [cartItems, setCartItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  // const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); //
  const cartItems = useSelector(state => state.cart.cartItems);
  
  const toggleDrawer = () => {
    handleLinkClick();
      setIsDrawerOpen(!isDrawerOpen);
  };
  const memoizedCheckLogin = useCallback(() => {
    checklogin();
  }, [checklogin]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        memoizedCheckLogin();
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken.role);
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, [memoizedCheckLogin]);
console.log(userRole);
  const handleLinkClick = () => {
    setExpanded(false); // Close the navbar when a link is clicked
  };

  // const logout = () => {
  //   window.open("https://homesalon.onrender.com/auth/logout", "_self");
  //   localStorage.removeItem('token');
  //   navigate("/");
  // };
  const removeFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
};
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top" expanded={expanded}>
      <div className="nava2">

<Container >
<div className="con">

<Navbar.Brand href="#home">
    <img src={logo} alt="" height={100} width={100} />
</Navbar.Brand>
<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
</div>
<div className="nava">
<Link className="nav-link" onClick={toggleDrawer }>
                            <IconButton aria-label="cart">
      <StyledBadge badgeContent={cartItems.length} color="secondary">
        <ShoppingCartIcon style={{color: 'aliceblue'}}/>
      </StyledBadge>
    </IconButton>
                            </Link>

              <Link to='/login' className="nav-link" onClick={handleLinkClick}>
                <Nav>Login</Nav>
              </Link>
                        
                </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            <Link to='/' className="nav-link" onClick={handleLinkClick}>
                <Nav>Home</Nav>
              </Link>
             
              <Link to='/Services' className="nav-link" onClick={handleLinkClick}>
                <Nav>Service</Nav>
              </Link>
              <Link to='/aboutus' className="nav-link" onClick={handleLinkClick}>
                <Nav>About US</Nav>
              </Link>
              <Link to='https://www.instagram.com/' className="nav-link" onClick={handleLinkClick}>
                <Nav> <InstagramIcon/> </Nav>
              </Link>
              <Link to='https://www.instagram.com/' className="nav-link" onClick={handleLinkClick}>
                <Nav> <Twitter/> </Nav>
              </Link>
              {/* Add your navigation links here */}
            </Nav>
        
          </Navbar.Collapse>
          </Container>
                </div>
     
      </Navbar>
      <Cartcop isOpen={isDrawerOpen} onClose={toggleDrawer} userRole={userRole} removeFromCart={removeFromCart}/>
    </div>
  );
};

export default Header;
