import React from 'react'
// import Address from '../myaccoutnts/Address'
// import { useNavigate, useNavigation } from 'react-router-dom'
// import { Button } from 'react-bootstrap'
import './Addresses.css'
import Addressshow from '../../Pages/Cart/Addressshow.jsx/Addressshow'
const Addresses = (userRole) => {
    // const navigation = useNavigate()
  return (
    <div className='addressdiv1'>
    <h1>Select Address</h1>
    <Addressshow userRole={userRole}/>
      <div style={{width:'520px'}}>

    
      </div>
      </div>
   
  )
}

export default Addresses
