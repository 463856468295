import React from 'react'
import './css/Home.css'
const Homeaboutus = () => {
  return (
    <div className='adiv1'>
        <h1 className="abh1">
           ABOUT US
        </h1>
        <p className="abp1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro delectus distinctio, nemo in aut quasi voluptas ea alias obcaecati laborum pariatur aliquam ab rem doloribus, ex explicabo dolor accusantium nisi?
        </p>

    </div>
  )
}

export default Homeaboutus